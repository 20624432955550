import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Paper, Text, Button, Flex, Center, Title, Image, Divider } from '@mantine/core'
import { MdDarkMode, MdLightMode, MdSettings } from 'react-icons/md'
import { FaWrench } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import decode from 'jwt-decode'
import { fetchUser } from '../../actions/auth'

import { useWindowResize } from '../Utilities/useWindowResize'
import { localStorageUserKey } from '../../constants/storageConstants'
import { SocketContext } from '../../context/socket'

const CustomHeader = ({ currentColorScheme, toggleColorScheme }) => {

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.65

    const [usersOnline, setUsersOnline] = useState(0)
    const socket = useContext(SocketContext)

    useEffect(() => {
        if (socket) {
            socket.emit('get_current_user_count')
            socket.on('got_current_user_count', (data) => {
                setUsersOnline(data)
            });
        }
        return () => {
            if (socket) {
                socket.off('got_current_user_count');
            }
        }
    }, [socket]);

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [user, setUser] = useState(JSON.parse(localStorage.getItem(localStorageUserKey)))
    const [fetchedUser, setFetchedUser] = useState(null)

    useEffect(() => {
        if (user && user.result) {
            dispatch(fetchUser(user.result._id, setFetchedUser))
        }
    }, [])

    const logout = () => {
        if (socket) {
            socket.emit('abandon_all_commitments')
        }
        dispatch({ type: 'LOGOUT' })
        setUser(null)
        navigate('/')
        window.location.reload()
    }

    const settings = () => {
        navigate('/settings')
    }

    const adminButton = () => {
        navigate('/admin-portal')
    }

    useEffect(() => {
        const token = user?.token
        if (token) {
            const decodedToken = decode(token)
            if (decodedToken.exp * 1000 < Date.now()) {
                logout()
            }
        }
        setUser(JSON.parse(localStorage.getItem(localStorageUserKey)))
    }, [navigate])

    return (
        <Grid columns={2}>
            <Grid.Col span={1}>
                <Flex justify='flex-start' align='center' sx={{ height: '100%' }}>
                    <Flex align='center'>
                        <Image component={Link} to="/" width={50} src='/images/ui/gg_logo_bg.svg' />
                        <Flex direction='column' sx={{ marginLeft: 10 }}>
                            {!isNarrow &&
                                <Title mt={-5} lineClamp={1} font='Arial' sx={{ wordBreak: 'break-all', fontFamily: 'Arial' }}
                                    component={Link} to="/" fontWeight='0' order={3} weight={500} size={26}>
                                    Group Games
                                </Title>
                            }
                            <Text mt={-5} lineClamp={1} sx={{ wordBreak: 'break-all' }} component={Link} to="/" size={12}>
                                {'Online: ' + usersOnline}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Grid.Col>
            <Grid.Col span={1} >
                <Flex justify='flex-end' align='center' sx={{ height: '100%' }} >
                    {/*<Button onClick={toggleColorScheme} size="sm" sx={{ marginRight: 10 }}>
                        {currentColorScheme === 'dark' ?
                            <MdLightMode />
                            :
                            <MdDarkMode />
                        }
                    </Button>
                    */}
                    {fetchedUser && fetchedUser.permissionLevel >= 2 &&
                        <Button onClick={adminButton} size="sm" sx={{ marginRight: 10 }}>
                            <FaWrench size={18} />
                        </Button>
                    }
                    {fetchedUser &&
                        <Button onClick={settings} size="sm" sx={{ marginRight: 10 }}>
                            <MdSettings size={18} />
                        </Button>
                    }
                    <Divider orientation="vertical" mr={10} />
                    {fetchedUser ?
                        <Button onClick={logout} size="sm">
                            Logout
                        </Button>
                        :
                        <Button component={Link} to="/auth" size="sm">
                            Login
                        </Button>
                    }
                </Flex>
            </Grid.Col>
        </Grid>
    )

}

export default CustomHeader