import React, { useEffect, useState, useContext } from 'react'
import { Badge, Button, Center, Container, Flex, Grid, Loader, NumberInput, Slider, Stack, Text } from '@mantine/core'

import { showNotification } from '@mantine/notifications'

import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import CheckerPiece from './CheckerPiece'
import CheckersSquare from './CheckersSquare'

import { AiFillCheckCircle } from 'react-icons/ai'

import { useWindowResize } from '../Utilities/useWindowResize'

const CheckersBoard = ({ user, refreshUser, setUser, currentGameInstance, setCurrentGameInstance, tileHighlights }) => {
    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    if (!currentGameInstance || !currentGameInstance.gameState) {
        return (
            <Flex p='lg' justify="center" align="center">
                <Loader />
            </Flex>
        )
    } else {
        //console.log(currentGameInstance)
    }

    const highlightTile = (rowIndex, squareIndex) => {
        //check if tile highlights contains this square
        if (tileHighlights) {
            for (let i = 0; i < tileHighlights.length; i++) {
                if (tileHighlights[i].col === squareIndex && tileHighlights[i].row === rowIndex) {
                    return true
                }
            }
        }
        return false
    };

    const getSquareColor = (rowIndex, squareIndex) => {
        if (rowIndex % 2 === 0) {
            if (squareIndex % 2 === 0) {
                return 'gray'
            } else {
                return 'black'
            }
        } else {
            if (squareIndex % 2 === 0) {
                return 'black'
            } else {
                return 'gray'
            }
        }
    };

    const getPieceColor = (piece) => {
        if (piece === 1 || piece === 3) {
            return 'black'
        } else {
            return 'red'
        }
    };

    const pieceIsKing = (piece) => {
        return piece === 3 || piece === 4;
    };

    const redSideBoard = () => {
        return (
            [...Array(currentGameInstance.gameState.currentBoard[0].length)].map((_, columnIndex) => {
                return (
                    <Flex key={columnIndex} direction='column'>
                        {
                            currentGameInstance.gameState.currentBoard.map((row, rowIndex) => {
                                const square = row[columnIndex];
                                return (
                                    <CheckersSquare gameInstance={currentGameInstance} user={user} color={getSquareColor(rowIndex, columnIndex)}
                                        x={columnIndex} y={rowIndex} highlight={highlightTile(rowIndex, columnIndex)} key={rowIndex}>
                                        {
                                            square !== 0 ?
                                                <CheckerPiece gameInstance={currentGameInstance} user={user}
                                                    x={columnIndex} y={rowIndex} color={getPieceColor(square)} king={pieceIsKing(square)} />
                                                :
                                                null
                                        }
                                    </CheckersSquare>
                                )
                            })
                        }
                    </Flex>
                )
            }
            )
        )
    };

    const blackSideBoard = () => {
        return (
            [...Array(currentGameInstance.gameState.currentBoard[0].length)].reverse().map((_, columnIndex) => {
                return (
                    <Flex key={currentGameInstance.gameState.currentBoard[0].length - 1 - columnIndex} direction='column'>
                        {
                            currentGameInstance.gameState.currentBoard.slice().reverse().map((row, rowIndex) => {
                                const square = row[currentGameInstance.gameState.currentBoard[0].length - 1 - columnIndex];
                                return (
                                    <CheckersSquare gameInstance={currentGameInstance} user={user} color={getSquareColor(currentGameInstance.gameState.currentBoard.length - 1 - rowIndex, currentGameInstance.gameState.currentBoard[0].length - 1 - columnIndex)}
                                        x={currentGameInstance.gameState.currentBoard[0].length - 1 - columnIndex} y={currentGameInstance.gameState.currentBoard.length - 1 - rowIndex} highlight={highlightTile(currentGameInstance.gameState.currentBoard.length - 1 - rowIndex, currentGameInstance.gameState.currentBoard[0].length - 1 - columnIndex)} key={currentGameInstance.gameState.currentBoard.length - 1 - rowIndex}>
                                        {
                                            square !== 0 ?
                                                <CheckerPiece gameInstance={currentGameInstance} user={user}
                                                    x={currentGameInstance.gameState.currentBoard[0].length - 1 - columnIndex} y={currentGameInstance.gameState.currentBoard.length - 1 - rowIndex} color={getPieceColor(square)} king={pieceIsKing(square)} />
                                                :
                                                null
                                        }
                                    </CheckersSquare>
                                )
                            })
                        }
                    </Flex>
                )
            })
        )
    };


    return (
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <Center>
                {
                    currentGameInstance.gameState.white === user._id ? redSideBoard() : blackSideBoard()
                }
            </Center>
        </DndProvider>
    )
}

export default CheckersBoard