import React, { useEffect, useState, useContext } from 'react'
import { Badge, Button, Center, Container, Flex, Grid, Image, Loader, NumberInput, Slider, Stack, Text } from '@mantine/core'

import { DragPreviewImage, useDrag } from 'react-dnd'
import { ItemTypes } from './ItemTypes'

import { useWindowResize } from '../Utilities/useWindowResize'

const CheckerPiece = ({ gameInstance, user, x, y, color, king }) => {
    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'checkers_piece',
            item: { x, y },
            canDrag: () => true,
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [gameInstance.gameState],
    )

    return (
        <>
            <DragPreviewImage connect={preview} src={'/images/ui/checkers_pieces_' + color + (king ? '_king' : '') + '.svg'} />
            <div
                ref={drag}
                style={{
                    opacity: isDragging ? 1 : 1,
                }}
            >
                <Image src={'/images/ui/checkers_pieces_' + color + (king ? '_king' : '') + '.svg'} width={isNarrow ? 30 : 50} height={isNarrow ? 30 : 50}
                    sx={{ zIndex: 5 }} />
            </div>
        </>
    )
}

export default CheckerPiece