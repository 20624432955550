import React, { useEffect, useState, useContext } from 'react'
import { Button, Card, Center, Container, Divider, Flex, Grid, Input, Loader, Slider, Stack, Text, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { SocketContext } from '../../context/socket'

const AdminPortal = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    const [notificationTitle, setNotificationTitle] = useState('')
    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationColor, setNotificationColor] = useState('blue')
    const [destoryGameUsername, setDestoryGameUsername] = useState('')

    useEffect(() => {
        if (socket) {

        }
        return () => {
            if (socket) {

            }
        }
    }, [socket]);

    return (
        <div>
            <Flex p='md' sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
            })}>
                <Center sx={{ width: '100%' }}>
                    <Stack direction='column' gap='xs' sx={{ width: '100%' }}>
                        <Card shadow='md' sx={(theme) => ({
                            width: '100%',
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                        })}>
                            <Stack>
                                <Title order={4} weight={500}>Control Board</Title>
                                <Divider />
                                <Flex p='xs' direction='column' justify='center' align='center' gap='md' sx={{ minHeight: 40 }}>
                                    <Button color='red' variant='outline' onClick={() => {
                                        if (socket) {
                                            socket.emit("send_server_wide_refresh")
                                        }
                                    }}>
                                        Server Wide Refresh
                                    </Button>
                                    <Divider sx={{ width: '100%', maxWidth: 250 }} />
                                    <Input placeholder='Title' value={notificationTitle} onChange={(event) => setNotificationTitle(event.target.value)} />
                                    <Input placeholder='Message' value={notificationMessage} onChange={(event) => setNotificationMessage(event.target.value)} />
                                    <Input placeholder='Color' value={notificationColor} onChange={(event) => setNotificationColor(event.target.value)} />
                                    <Button color='red' variant='outline' onClick={() => {
                                        if (socket) {
                                            socket.emit("send_server_wide_notification", {
                                                notification: { title: notificationTitle, message: notificationMessage, color: notificationColor }
                                            })
                                        }
                                    }}>
                                        Server Wide Notification
                                    </Button>
                                    <Divider sx={{ width: '100%', maxWidth: 250 }} />
                                    <Button color='red' variant='outline' onClick={() => {
                                        if (socket) {
                                            socket.emit("destroy_all_game_instances")
                                        }
                                    }}>
                                        Destroy All Game Instances
                                    </Button>
                                    <Divider sx={{ width: '100%', maxWidth: 250 }} />
                                    <Button color='red' variant='outline' onClick={() => {
                                        if (socket) {
                                            socket.emit("destroy_all_groups")
                                        }
                                    }}>
                                        Destroy All Groups
                                    </Button>
                                    <Divider sx={{ width: '100%', maxWidth: 250 }} />
                                    <Button color='red' variant='outline' onClick={() => {
                                        if (socket) {
                                            socket.emit("destroy_all_conversations")
                                        }
                                    }}>
                                        Destroy All Conversations
                                    </Button>
                                    <Divider sx={{ width: '100%', maxWidth: 250 }} />
                                    <Input placeholder='Username' value={destoryGameUsername} onChange={(event) => setDestoryGameUsername(event.target.value)} />
                                    <Button color='red' variant='outline' onClick={() => {
                                        if (socket) {
                                            socket.emit("destroy_user_game_instance", { username: destoryGameUsername })
                                        }
                                    }}>
                                        Destroy User Game Instance
                                    </Button>
                                </Flex>
                            </Stack>
                        </Card>
                    </Stack>
                </Center>
            </Flex>
        </div>
    )

}

export default AdminPortal