import React, { useEffect, useState, useContext } from 'react'
import { Button, Flex, Grid, Image, Loader, Menu, Overlay, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { useNavigate } from 'react-router-dom'
import NumericLabel from 'react-pretty-numbers'

import { SocketContext } from '../../context/socket'

const UserDisplay = ({ user, userId, showFunds, fetchUserVersion, useMenu, checkIsOnline, justifyOverride }) => {
    const socket = useContext(SocketContext)
    const navigate = useNavigate()

    const [fetchedUser, setFetchedUser] = useState(null)

    useEffect(() => {
        if (socket) {
            if (checkIsOnline) {
                socket.emit('fetch_user', { userId, context: 'user_display', checkIsOnline: true })
            } else {
                socket.emit('fetch_user', { userId, context: 'user_display' })
            }
            socket.on('fetched_user', (data) => {
                if (data && data.result && data.result.context === 'user_display' && data.result.updatedUser &&
                data.result.updatedUser._id === userId) {
                    setFetchedUser(data)
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('fetched_user');
            }
        }
    }, [socket, fetchUserVersion]);

    const sendFriendRequest = (username) => {
        if (socket) {
            socket.emit('send_friend_request', { username })
        }
    };

    const voteToKick = () => {
        if (socket) {
            socket.emit('vote_to_kick_from_game', { userId })
        }
    };

    let prettyNumberOptions = {
        'justification': 'C',
        'shortFormat': true,
        'shortFormatMinValue': 10000,
        'shortFormatPrecision': 2
    }

    const getFundsString = () => {
        return (
            <Flex justify={justifyOverride ? justifyOverride : 'center'} align='center'>
                <Image width={16} src='/images/ui/dollar.svg' />
                &nbsp;&nbsp;
                <Text weight={400}>
                    <NumericLabel params={prettyNumberOptions}>
                        {fetchedUser.result.updatedUser.funds}
                    </NumericLabel>
                </Text>
            </Flex>
        )
    };

    const canSendFriendRequest = () => {
        if (user.friends.includes(userId)) {
            return false
        }
        if (user.friendRequests.includes(userId)) {
            return false
        }
        return true
    };

    if (!fetchedUser || !fetchedUser.result) {
        return (
            <div>
                <Grid columns={3} sx={{ width: '100%' }}>
                    <Grid.Col span={1} offset={1} >
                        <Loader variant='dots' />
                    </Grid.Col>
                </Grid>
            </div>
        )
    }

    return (
        <Flex justify={justifyOverride ? justifyOverride : 'center'} align='center' sx={{ width: '100%', height: '100%' }}>
            {
                useMenu && user && user._id !== userId ? (
                    <Menu shadow="md" width={150} position='top' withArrow offset={10} arrowSize={12}>
                        <Menu.Target>
                            <Text weight={600} truncate='end' sx={{ zIndex: 20, width: '100%' }}>
                                {fetchedUser.result.updatedUser.username}
                                {showFunds && getFundsString()}
                            </Text>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label>{fetchedUser.result.updatedUser.username}</Menu.Label>
                            <Menu.Divider />
                            <Menu.Item onClick={() => {
                                navigate(`/user/${userId}`)
                            }}>
                                <Text size='xs' weight={700}>
                                    View Profile
                                </Text>
                            </Menu.Item>
                            <Menu.Divider />
                            {canSendFriendRequest() &&
                                <Menu.Item disabled={!canSendFriendRequest()} onClick={() => { sendFriendRequest(fetchedUser.result.updatedUser.username) }}>
                                    <Text size='xs' weight={700}>
                                        Send Friend Request
                                    </Text>
                                </Menu.Item>}
                            <Menu.Item disabled>
                                <Text size='xs' weight={700}>
                                    Send Message
                                </Text>
                            </Menu.Item>
                            <Menu.Item color='red' onClick={() => { voteToKick() }}>
                                <Text size='xs' weight={700}>
                                    Vote to Kick
                                </Text>
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                ) : (
                    <Text ml={checkIsOnline ? 6 : 0} weight={600} truncate='end' sx={{ zIndex: 20, width: '100%' }}>
                        <Flex justify={justifyOverride ? justifyOverride : 'center'} align='center'>
                            {
                                checkIsOnline &&
                                <Flex justify={justifyOverride ? justifyOverride : 'center'} align='center'
                                    mr={14}
                                    sx={{
                                        width: 10, height: 10,
                                        backgroundColor: (fetchedUser.result.isOnline || (user && user._id && user._id === userId)) ? 'green' : 'red',
                                        borderRadius: 999
                                    }} />
                            }
                            {`${fetchedUser.result.updatedUser.username}`}
                        </Flex>
                        {showFunds && getFundsString()}
                    </Text>
                )
            }
        </Flex>
    )

}

export default UserDisplay