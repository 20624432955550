import React, { useEffect, useContext, useState } from 'react'
import { Button, Card, Center, Container, Flex, Stack, Text, Title } from '@mantine/core'

import { SocketContext } from '../../context/socket'

const FundsFountain = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    const [globalState, setGlobalState] = useState(null)

    useEffect(() => {
        if (socket) {
            socket.emit("get_global_state")
            socket.on("got_global_state", (data) => {
                if (data && data.result && data.result.globalState) {
                    setGlobalState(data.result.globalState)
                }
            })
            socket.on('requested_funds_from_charity', (data) => {
                if (data && data.result && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                    if (data.result.updatedGlobalState) {
                        setGlobalState(data.result.updatedGlobalState)
                    }
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("got_global_state")
                socket.off('requested_funds_from_charity')
            }
        }
    }, [socket]);

    return (
        <div>
            <Flex sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                padding: theme.spacing.sm,
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                paddingLeft: 12,
            })}>
                <Center>
                    <Card shadow='md' sx={(theme) => ({ marginBottom: 20, marginTop: 10, backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1] })}>
                        <Stack sx={{ width: '100%' }}>
                            <Title order={3}>
                                {`Total Funds Disbursed: ${globalState ? globalState.totalFundsDisbursedByFountain : 0}`}
                            </Title>
                            <Text size='sm'>
                                This is for poor players only. Will only work if you have less than 500 funds.
                            </Text>
                            <Center>
                                <Button variant='filled' color='blue' onClick={() => {
                                    if (socket) {
                                        socket.emit("request_funds_from_charity")
                                    }
                                }}>
                                    <Text size='sm'>
                                        Get Funds
                                    </Text>
                                </Button>
                            </Center>
                        </Stack>
                    </Card>
                </Center>
            </Flex>
        </div>
    )

}

export default FundsFountain