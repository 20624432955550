const getHighestBet = (gameState) => {
    const playerStatesPlayers = Object.keys(gameState.playerStates);
    let highestBet = 0;
    for (let i = 0; i < playerStatesPlayers.length; i++) {
        const playerState = gameState.playerStates[playerStatesPlayers[i]];
        if (playerState.bet > highestBet) {
            highestBet = playerState.bet;
        }
    }
    return highestBet;
};

export const getAvailablePokerActions = (gameState, fetchedUser) => {
    try {
        if (!gameState) {
            return Error("no game state supplied");
        }
        if (!gameState.playerStates) {
            return Error("no player states found");
        }
        if (!fetchedUser) {
            return Error("no user supplied");
        }
        const userId = fetchedUser._id;
        const playerStatesPlayers = Object.keys(gameState.playerStates);
        if (!playerStatesPlayers.includes(userId)) {
            return Error("user not found in player states");
        }
        if (gameState.phase === 0) {
            if (playerStatesPlayers[0] === userId) {
                return ["start", "leave"]
            }
            return ["leave"]
        }
        if (gameState.turn !== userId) {
            return [];  // It's not this player's turn, so no moves are available
        }
        const playerState = gameState.playerStates[userId];
        if (!playerState) {
            return Error("no player state found");
        }
        if (playerState.folded || playerState.allIn) {
            return [];
        }
        const highestBet = getHighestBet(gameState);
        const availableActions = ["fold"];  // A player can always choose to fold
        if (Number(playerState.bet) < highestBet) {
            availableActions.push("call");
            if (fetchedUser.funds >= highestBet) {
                availableActions.push("raise");
            }
        } else if (Number(playerState.bet) === highestBet) {
            if (highestBet === 0) {
                // If no one has bet yet in this round, the player can check or bet
                availableActions.push("check");
                if (fetchedUser.funds > 0) {
                    availableActions.push("bet");
                }
            } else {
                if (playerState.phasesActed && !playerState.phasesActed.includes(gameState.phase)) {
                    availableActions.push("raise");
                }
                // If someone has bet in this round and the player has called, they can only check if they are not all-in
                availableActions.push("check");
            }
        }
        return availableActions;
    } catch (error) {
        return Error(error.message);
    }
};

export const getLabelForPokerAction = (action) => {
    switch (action) {
        case "start":
            return "Start";
        case "leave":
            return "Leave";
        case "fold":
            return "Fold";
        case "call":
            return "Call";
        case "raise":
            return "Raise";
        case "check":
            return "Check";
        case "bet":
            return "Bet";
        case "checkfold":
            return "Check / Fold";
        case "callany":
            return "Call Any";
        case "autocheck":
            return "Try Check";
        case "show_cards":
            return "Show Cards";
        default:
            return "Check";
    }
};