import { UPDATE_USER, CHECK_USER } from '../constants/actionTypes'

const usersReducer = (state = { authData: null }, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return { ...state, authData: action?.data }
        case CHECK_USER:
            return action?.data
        default:
            return state;
    }
}

export default usersReducer