import { AUTH, LOGOUT, FETCH_USER } from '../constants/actionTypes'
import { localStorageUserKey } from '../constants/storageConstants'

const authReducer = (state = { authData: null }, action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem(localStorageUserKey, JSON.stringify({ ...action?.data }))
            localStorage.setItem('has-logged-in', 'true')
            return { ...state, authData: action?.data }
        case LOGOUT:
            localStorage.removeItem(localStorageUserKey)
            return { ...state, authData: null }
        case FETCH_USER:
            return { ...state, authData: action?.data }
        default:
            return state;
    }
}

export default authReducer