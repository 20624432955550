import React, { useEffect, useState, useContext } from 'react'
import { Center, Container, Flex, Grid, Loader, ScrollArea, Stack, Text } from '@mantine/core'

import GameSelectItem from '../GameSelectItem/GameSelectItem'

import { SocketContext } from '../../context/socket'

const GameSelectMenu = ({ user, refreshUser, setUser, setSelectedGame, setPublicGameTypeForListings, findGame, playGame }) => {
    const socket = useContext(SocketContext)

    const [games, setGames] = useState([])
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight)
    });

    useEffect(() => {
        if (socket) {
            if (!games || games.length === 0) {
                socket.emit('get_all_games')
            }
            socket.on('got_all_games', (data) => {
                if (data && data.result && data.result.games) {
                    //sort games by underContruction, then by category, then by name
                    const sortedGames = data.result.games.sort((a, b) => {
                        if (a.underConstruction && !b.underConstruction) {
                            return 1
                        } else if (!a.underConstruction && b.underConstruction) {
                            return -1
                        } else if (a.category > b.category) {
                            return 1
                        } else if (a.category < b.category) {
                            return -1
                        } else if (a.name > b.name) {
                            return 1
                        } else if (a.name < b.name) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                    setGames(sortedGames)
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('got_all_games')
            }
        }
    }, [socket]);

    return (
        <Flex p='md' sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
            textAlign: 'center',
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        })}>
            <Center sx={{ width: '100%' }}>
                <ScrollArea offsetScrollbars type='always' sx={{ width: '100%', height: `${windowHeight - 170}px` }}>
                    <Grid columns={1} sx={{ width: '100%' }}>
                        {
                            games ? (
                                games.map((game, index) => {
                                    return (
                                        <Grid.Col key={game._id} span={1}  >
                                            <GameSelectItem user={user} game={game} setSelectedGame={setSelectedGame}
                                                setPublicGameTypeForListings={setPublicGameTypeForListings} findGame={findGame} playGame={playGame} />
                                        </Grid.Col>
                                    )
                                })
                            ) : (
                                <Loader variant='dots' />
                            )
                        }
                    </Grid>
                </ScrollArea>
            </Center>
        </Flex>
    )

}

export default GameSelectMenu