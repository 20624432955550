import React, { useEffect, useState, useContext } from 'react'
import { Button, Dialog, Text, Stack, Grid, Loader, Divider } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks';
import PlayerAvatarSmall from '../PlayerAvatarSmall/PlayerAvatarSmall'

import { SocketContext } from '../../context/socket'

const GroupInviteDialog = ({ user, setUser, refreshUser }) => {
    const socket = useContext(SocketContext)

    const [fetchedGroup, setFetchedGroup] = useState(null)
    const [fetchedSender, setFetchedSender] = useState(null)
    const [fetchedReceiver, setFetchedReceiver] = useState(null)
    const [opened, { toggle, close }] = useDisclosure(false);

    useEffect(() => {
        if (socket) {
            socket.on("receive_group_invite", (data) => {
                if (data && data.sender && data.receiver && data.group) {
                    setFetchedSender(data.sender)
                    setFetchedReceiver(data.receiver)
                    setFetchedGroup(data.group)
                    toggle()
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("receive_group_invite")
            }
        }
    }, [socket]);

    const acceptGroupInvite = () => {
        if (socket) {
            socket.emit("join_group", { groupId: fetchedGroup._id })
            close()
        }
    };

    if (!fetchedSender || !fetchedReceiver || !fetchedGroup) {
        return null
    }

    return (
        <Dialog withCloseButton onClose={close} opened={opened} size={230} padding="xs" radius={10} zIndex={200}>
            <Text mt={-8} size="md" weight={700}>
                Group Invite
            </Text>
            <Divider mt={10} />
            <Text mt={10} size="md" weight={500}>
                {fetchedSender.username} has invited you to join their group!
            </Text>
            <Grid columns={2} mt={10}>
                <Grid.Col span={1}>
                    <Button fullWidth onClick={() => acceptGroupInvite()} color="blue" variant="light" radius={10}>
                        Accept
                    </Button>
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button fullWidth onClick={() => close()} color="red" variant="light" radius={10}>
                        Decline
                    </Button>
                </Grid.Col>
            </Grid>
        </Dialog>
    )

}

export default GroupInviteDialog