import React, { useEffect, useContext } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { SocketContext } from '../../context/socket'

const GameJoinMenu = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    const location = useLocation()
    const splitPath = location.pathname.split('/')
    const gameId = splitPath[splitPath.length - 1]

    const navigate = useNavigate()

    const joinGame = () => {
        if (socket) {
            socket.emit("join_game", { gameInstanceId: gameId })
            navigate(`/games`)
        }
    };

    useEffect(() => {
        if (gameId) {
            joinGame()
        }
    }, [socket]);

    return null;

}

export default GameJoinMenu