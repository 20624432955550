import React, { useEffect, useState, useContext } from 'react'
import { Button, Card, Center, Container, Divider, Flex, Grid, Image, Loader, ScrollArea, Stack, Text, Title } from '@mantine/core'

import PublicGameListItem from './PublicGameListItem'

import { FiRefreshCw } from 'react-icons/fi'
import { IoMdArrowRoundBack } from 'react-icons/io'

import { SocketContext } from '../../context/socket'
import { useWindowResize } from '../Utilities/useWindowResize'

const PublicGameListings = ({ user, refreshUser, setUser, gameType, setPublicGameTypeForListings }) => {
    const socket = useContext(SocketContext)

    const [games, setGames] = useState([])
    const [gameTypeObject, setGameTypeObject] = useState(null)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight)
    });

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    const refreshGames = () => {
        if (socket) {
            setGames([])
            socket.emit('get_next_ten_public_games_for_type', { gameType: gameType, lastGameId: null })
        }
    }

    useEffect(() => {
        if (socket) {
            if (!games || games.length === 0) {
                refreshGames()
            }
            socket.on('got_next_ten_public_games_for_type', (data) => {
                if (data && data.result && (data.result.gameInstances || data.result.gameType)) {
                    setGames(data.result.gameInstances)
                    setGameTypeObject(data.result.gameType)
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('got_next_ten_public_games_for_type')
            }
        }
    }, [socket]);

    const backButton = () => {
        setPublicGameTypeForListings(null)
    }

    if (!gameTypeObject) {
        return null
    }

    return (
        <Flex p='md' sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
            textAlign: 'center',
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        })}>
            <Center sx={{ width: '100%' }}>
                <Stack spacing='md' sx={{ width: '100%' }}>
                    <Card p={20} shadow='md' sx={(theme) => ({
                        width: '100%',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                    })}>
                        <Flex direction='row' justify='space-between' align='center'>
                            <Stack spacing={10} sx={{ position: isNarrow ? 'none' : 'absolute' }}>
                                <Button variant='outline' color='red' size='md' onClick={backButton}>
                                    <IoMdArrowRoundBack size={20} />
                                </Button>
                                <Button variant='outline' color='blue' size='md' onClick={refreshGames}>
                                    <FiRefreshCw />
                                </Button>
                            </Stack>
                            <Flex ml={20} direction='row' justify='center' align='center' sx={{ width: '100%' }}>
                                <Image mr={20} height={100 * 0.8} src={gameTypeObject.imageSource} radius={10} sx={{ maxWidth: 130 * 0.8 }} />
                                <Title align='center' order={isNarrow ? 4 : 2} weight={500}>
                                    {gameTypeObject.name}
                                </Title>
                            </Flex>
                        </Flex>
                    </Card>
                    <Divider />
                    <ScrollArea offsetScrollbars type='always' sx={{ width: '100%', height: `${windowHeight - 170}px` }}>
                        <Grid columns={1} sx={{ width: '100%' }}>
                            {
                                games && games.length ? (
                                    games.map((game, index) => {
                                        return (
                                            <Grid.Col key={game._id} span={1}  >
                                                <PublicGameListItem user={user} gameInstance={game} gameType={gameTypeObject}
                                                    setPublicGameTypeForListings={setPublicGameTypeForListings} />
                                            </Grid.Col>
                                        )
                                    })
                                ) : (
                                    <Card p={20} shadow='md' sx={(theme) => ({
                                        width: '100%',
                                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                                    })}>
                                        <Flex direction='row' justify='center' align='center'>
                                            <Text pt={8} weight={600} size='lg' align='center' justify='center'>
                                                No Games Found
                                            </Text>
                                        </Flex>
                                    </Card>
                                )
                            }
                        </Grid>
                    </ScrollArea>
                </Stack>
            </Center>
        </Flex>
    )

}

export default PublicGameListings