import React, { useEffect, useState, useContext } from 'react'
import { Badge, Button, Card, Center, Container, Flex, Grid, Image, Loader, NumberInput, Overlay, Slider, Stack, Text } from '@mantine/core'

import { showNotification } from '@mantine/notifications'

import { AiFillCheckCircle } from 'react-icons/ai'
import { BsXCircleFill } from 'react-icons/bs'

import UserDisplay from '../UserDisplay/UserDisplay'

import { useWindowResize } from '../Utilities/useWindowResize'

const PlayerDisplay = ({ userId, index, gameInstance, user, fetchUserVersion }) => {
    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    const gameState = gameInstance.gameState || null

    const loading = () => {
        return (
            <Flex p='lg' justify="center" align="center" sx={{ height: '100%' }}>
                <Loader variant='dots' />
            </Flex>
        )
    };

    if (!gameInstance || !gameState) {
        return (
            loading()
        )
    }

    if (!user || !userId) {
        return (
            loading()
        )
    }

    return (
        <Flex pt={12} direction='column' justify='center' align='center' sx={{ height: '100%', position: 'relative' }}>
            {
                gameInstance.gameState.turn.toString() === userId && (<Overlay opacity={0.15} zIndex={10} radius={25} color='orange' />)
            }
            <Image width={32} src={userId === gameInstance.gameState.white ?
                '/images/ui/checkers_pieces_red.svg' :
                '/images/ui/checkers_pieces_yellow.svg'} sx={{ zIndex: 15 }} />
            <UserDisplay user={user} userId={userId} showFunds={true} useMenu={true} fetchUserVersion={fetchUserVersion} />
        </Flex>
    )

}

export default PlayerDisplay