import React, { useContext, useEffect, useState } from 'react'
import { MantineProvider, AppShell, Header, ColorSchemeProvider, Overlay, Flex, Button, Popover, Dialog, Text, Stack, Footer, Grid, Container, Tabs, Space, Drawer } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications';

import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import './App.css'
import CustomHeader from './components/CustomHeader/CustomHeader'
import Home from './components/Home/Home'
import Auth from './components/Auth/Auth'
import Friends from './components/Friends/Friends'
import GameHomeMenu from './components/GameHomeMenu/GameHomeMenu';
import GameJoinMenu from './components/GameHomeMenu/GameJoinMenu';
import PageNotFound from './components/PageNotFound/PageNotFound';
import GroupDisplay from './components/GroupDisplay/GroupDisplay';
import GroupInviteDialog from './components/GroupInviteDialog/GroupInviteDialog';
import GameInviteDialog from './components/GameInviteDialog/GameInviteDialog';
import LeaderboardMenu from './components/LeaderboardMenu/LeaderboardMenu';
import GlobalNotifications from './components/GlobalNotifications/GlobalNotifications';
import GlobalSocketEffect from './components/GlobalSocketEffect/GlobalSocketEffect';
import StatsAndButtonsWrapper from './components/StatsAndButtonsWrapper/StatsAndButtonsWrapper';
import FundsFountain from './components/FundsFountain/FundsFountain';
import SettingsMenu from './components/SettingsMenu/SettingsMenu';
import AdminPortal from './components/AdminPortal/AdminPortal';

import { GoogleOAuthProvider } from '@react-oauth/google'

import { getSocket, SocketContext } from './context/socket';
import { localStorageUserKey } from './constants/storageConstants';
import { useWindowResize } from './components/Utilities/useWindowResize';

import { RiGroupFill } from 'react-icons/ri'
import LeftPanel from './components/LeftPanel/LeftPanel';
import RightPanel from './components/RightPanel/RightPanel';
import UserProfile from './components/UserProfile/UserProfile';
import NewButtonBar from './components/NewButtonBar/NewButtonBar';
import MainPlayerStatsHeader from './components/MainPlayerStatsHeader/MainPlayerStatsHeader';
import { BsChatDotsFill } from 'react-icons/bs';

const App = () => {
  const [socket, setSocket] = useState(null);

  const localUser = JSON.parse(localStorage.getItem(localStorageUserKey))
  const hasLoggedIn = localStorage.getItem('has-logged-in')
  const [user, setUser] = useState(localUser?.result)

  const aspectRatio = useWindowResize();
  const isMedium = aspectRatio.width / aspectRatio.height < 1.2
  const isNarrow = aspectRatio.width / aspectRatio.height < 0.65

  const [chatOpened, setChatOpened] = useState(false);
  const [friendsOpened, setFriendsOpened] = useState(false);

  useEffect(() => {
    if (!localUser) {
      return
    }

    // Connect to the socket server
    const socket = getSocket();

    // Save the socket instance in the state
    setSocket(socket);

    if (socket) {
      refreshUser(socket)
      socket.emit("switch_screens")
      socket.on("got_user", (data) => {
        if (data.result) {
          setUser(data.result.updatedUser)
        }
      })
      if (hasLoggedIn) {
        socket.emit('user_log_in_update')
        localStorage.removeItem('has-logged-in')
      }
    }

    // Disconnect from the socket server when the component unmounts
    return () => {
      if (socket) {
        socket.emit("switch_screens")
        socket.off("got_user")
        socket.disconnect()
      }
    };
  }, []);

  const refreshUser = (socket) => {
    socket.emit('get_authed_user')
  }

  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (socket) {
      socket.emit("switch_screens")
    }
  }, [pathname])

  useEffect(() => {
    const interval = setInterval(() => {
      if (socket) {
        refreshUser(socket)
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [socket]);

  const [colorScheme, setColorScheme] = useState('dark');

  const toggleColorScheme = () => {
    setColorScheme(colorScheme === 'dark' ? 'light' : 'dark');
  }

  return (
    <ColorSchemeProvider colorScheme={colorScheme}>
      <MantineProvider theme={{ colorScheme: colorScheme }} withGlobalStyles withNormalizeCSS>
        <NotificationsProvider position='bottom-center'>
          <GoogleOAuthProvider clientId={`${process.env.REACT_APP_NEXT_PUBLIC_GOOGLE_API_TOKEN}`}>
            <SocketContext.Provider value={socket}>
              <GlobalNotifications user={user} refreshUser={refreshUser} setUser={setUser} />
              <GlobalSocketEffect user={user} refreshUser={refreshUser} setUser={setUser} />
              <AppShell
                padding="md"
                header={
                  <Header height={68} p="xs">
                    <CustomHeader currentColorScheme={colorScheme} toggleColorScheme={toggleColorScheme} />
                  </Header>
                }
                sx={(theme) => ({
                  main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
                  width: '100%'
                })}
                footer={
                  <div>
                    <Footer>
                      <div style={{ height: 3 }} />
                      <Flex justify='center' align='center' p={6} direction='row' >
                        <Text size={14}>
                          &copy; 2023 Hotkeys Games LLC
                        </Text>
                      </Flex>
                    </Footer>
                  </div>
                }
              >
                {
                  isMedium && localUser &&
                  <Dialog opened={true} size={50} padding="xs" radius={10} sx={{ minWidth: 110 }}>
                    <Stack direction="column" spacing="xs" align='center'>
                      <Button fullWidth variant='outline' color='gray' size='lg' onClick={() => { setChatOpened(true) }}>
                        <BsChatDotsFill size={20} />
                      </Button>
                      <Button fullWidth variant='outline' color='gray' size='lg' onClick={() => { setFriendsOpened(true) }}>
                        <RiGroupFill size={20} />
                      </Button>
                    </Stack>
                  </Dialog>
                }
                {
                  isMedium && localUser &&
                  <Drawer position='top' padding={10} size='90%' opened={chatOpened} onClose={() => { setChatOpened(false) }}
                    styles={{
                      closeButton: {
                        scale: '2',
                      },
                    }}>
                    <RightPanel user={user} refreshUser={refreshUser} setUser={setUser} />
                  </Drawer>
                }
                {
                  isMedium && localUser &&
                  <Drawer position='top' padding={10} size='90%' opened={friendsOpened} onClose={() => { setFriendsOpened(false) }}
                    styles={{
                      closeButton: {
                        scale: '2',
                      },
                    }}>
                    <LeftPanel user={user} refreshUser={refreshUser} setUser={setUser} />
                  </Drawer>
                }
                <Grid columns={100} sx={{ width: '100%' }}>
                  {
                    !isMedium &&
                    <Grid.Col span={20}>
                      {localUser &&
                        <LeftPanel user={user} refreshUser={refreshUser} setUser={setUser} showTopPanel={!isMedium} />
                      }
                    </Grid.Col>
                  }
                  <Grid.Col span={isMedium ? 100 : 60} >
                    {
                      isMedium && localUser &&
                      <>
                        <MainPlayerStatsHeader user={user} refreshUser={refreshUser} setUser={setUser} />
                        <Space h={10} />
                        <NewButtonBar user={user} refreshUser={refreshUser} setUser={setUser} />
                        <Space h={10} />
                      </>
                    }
                    <GroupInviteDialog user={user} refreshUser={refreshUser} setUser={setUser} />
                    <GameInviteDialog user={user} refreshUser={refreshUser} setUser={setUser} />
                    <Routes>
                      <Route path="/" exact element={localUser ? <Navigate to="/games" /> : <Home />} />
                      <Route path="/auth" exact element={localUser ? <Navigate to="/" /> : <Auth />} />
                      <Route path="/games" exact element={!localUser ? <Navigate to="/" /> : <GameHomeMenu user={user} refreshUser={refreshUser} setUser={setUser} />} />
                      <Route path="/games/play/:id" exact element={!localUser ? <Navigate to="/" /> : <GameJoinMenu user={user} refreshUser={refreshUser} setUser={setUser} />} />
                      <Route path="/user/:id" exact element={!localUser ? <Navigate to="/" /> : <UserProfile user={user} refreshUser={refreshUser} setUser={setUser} />} />
                      <Route path="/leaderboard" exact element={!localUser ? <Navigate to="/" /> : <LeaderboardMenu user={user} refreshUser={refreshUser} setUser={setUser} />} />
                      <Route path="/fundsfountain" exact element={!localUser ? <Navigate to="/" /> : <FundsFountain user={user} refreshUser={refreshUser} setUser={setUser} />} />
                      <Route path="/settings" exact element={!localUser ? <Navigate to="/" /> : <SettingsMenu user={user} refreshUser={refreshUser} setUser={setUser} />} />
                      <Route path="/admin-portal" exact element={!localUser || !user || user.permissionLevel < 2 ? <Navigate to="/" /> : <AdminPortal user={user} refreshUser={refreshUser} setUser={setUser} />} />
                      <Route path="*" exact element={<PageNotFound />} />
                    </Routes>
                  </Grid.Col>
                  {
                    !isMedium &&
                    <Grid.Col span={20}>
                      {localUser &&
                        <RightPanel user={user} refreshUser={refreshUser} setUser={setUser} showTopPanel={!isMedium} />
                      }
                    </Grid.Col>
                  }
                </Grid>
              </AppShell>
            </SocketContext.Provider>
          </GoogleOAuthProvider >
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  )

}

export default App