import React, { useEffect, useState, useContext } from 'react'
import { Button, Card, Center, Container, Divider, Flex, Grid, Input, Loader, Slider, Stack, Text, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useLocalStorage } from '@mantine/hooks';

import { SocketContext } from '../../context/socket'

const SettingsMenu = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    const [editingUsername, setEditingUsername] = useState(false)
    const [currentUsername, setCurrentUsername] = useState(user.username)

    const [alertVolume, setAlertVolume] = useLocalStorage({
        key: 'alert-volume',
        defaultValue: '30',
    });

    const [sfxVolume, setSFXVolume] = useLocalStorage({
        key: 'sfx-volume',
        defaultValue: '30',
    });

    useEffect(() => {
        if (socket) {
            socket.on("changed_username", (data) => {
                if (data && data.result && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                    showNotification({
                        title: 'Username Changed',
                        message: `Your username has been changed to ${data.result.updatedUser.username}!`,
                        color: 'blue',
                    })
                } else {
                    showNotification({
                        title: 'Username Change Failed',
                        message: `Your username could not be changed!`,
                        color: 'red',
                    })
                    setCurrentUsername(user.username)
                }
            })
        }
        return () => {
            if (socket) {
                //socket.off("joined_game")
                socket.off("changed_username")
            }
        }
    }, [socket]);

    const handleUsernameChange = () => {
        if (socket) {
            socket.emit("change_username", { newUsername: currentUsername })
        }
        setEditingUsername(false)
    }

    return (
        <div>
            <Flex p='md' sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
            })}>
                <Center sx={{ width: '100%' }}>
                    <Stack direction='column' gap='xs' sx={{ width: '100%' }}>
                        <Card shadow='md' sx={(theme) => ({
                            width: '100%',
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                        })}>
                            <Stack direction='column' gap='xs'>
                                <Title order={4} weight={500}>Account</Title>
                                <Divider />
                                <Flex direction='row' justify='center' align='center' gap='xs' sx={{ minHeight: 40 }}>
                                    <Text>Username:</Text>
                                    {
                                        editingUsername ? (
                                            <Flex direction='row' justify='center' align='center' gap='xs'>
                                                <Input value={currentUsername} minLength={3} maxLength={30} onChange={(event) => {
                                                    setCurrentUsername(event.target.value)
                                                }} />
                                                <Button variant='light' size='xs' onClick={() => {
                                                    handleUsernameChange()
                                                }}>Accept</Button>
                                            </Flex>
                                        ) : (
                                            <Flex direction='row' justify='center' align='center' gap='xs'>
                                                <Text>{user.username}</Text>
                                                <Button variant='light' size='xs' onClick={() => {
                                                    setEditingUsername(true)
                                                }}>Change</Button>
                                            </Flex>
                                        )
                                    }

                                </Flex>
                            </Stack>
                        </Card>
                        <Card shadow='md' sx={(theme) => ({
                            width: '100%',
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                        })}>
                            <Stack direction='column' gap='xs' sx={{ width: '100%' }}>
                                <Title order={4} weight={500}>Sound</Title>
                                <Divider />
                                <Flex direction='row' justify='center' align='center' gap='xs' sx={{ minHeight: 40, width: '100%' }}>
                                    <Center sx={{ width: '100%' }}>
                                        <Stack direction='column' justify='center' align='center' gap='xs' sx={{ width: '100%' }}>
                                            <Text>Alert Volume:</Text>
                                            <Slider value={alertVolume} onChange={setAlertVolume} sx={{ width: '100%', maxWidth: 250 }} />
                                        </Stack>
                                    </Center>
                                </Flex>
                                <Flex direction='row' justify='center' align='center' gap='xs' sx={{ minHeight: 40, width: '100%' }}>
                                    <Center sx={{ width: '100%' }}>
                                        <Stack direction='column' justify='center' align='center' gap='xs' sx={{ width: '100%' }}>
                                            <Text>SFX Volume:</Text>
                                            <Slider value={sfxVolume} onChange={setSFXVolume} sx={{ width: '100%', maxWidth: 250 }} />
                                        </Stack>
                                    </Center>
                                </Flex>
                            </Stack>
                        </Card>
                    </Stack>
                </Center>
            </Flex>
        </div>
    )

}

export default SettingsMenu