import React, { useEffect, useState, useContext, useRef } from 'react'
import { Badge, Button, Center, Container, Divider, Flex, Grid, Image, Loader, Modal, NumberInput, SimpleGrid, Slider, Stack, Text } from '@mantine/core'

import { showNotification } from '@mantine/notifications'
import { useLocalStorage } from '@mantine/hooks'

import PlayerDisplay from './PlayerDisplay'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

import { keyframes } from '@emotion/react';
// Define the flip animation
const flip = keyframes`
  0% { transform: perspective(600px) rotateY(0deg) scale(1); }
  50% { transform: perspective(600px) rotateY(180deg) scale(1); }
  100% { transform: perspective(600px) rotateY(360deg) scale(1); }
`

// Define the settle animation
const settle = keyframes`
  0% { transform: perspective(600px) rotateY(360deg) scale(1); }
  100% { transform: perspective(600px) rotateY(0deg) scale(1); }
`;

const CoinFlipGame = ({ user, refreshUser, setUser, currentGameInstance, setCurrentGameInstance, setIsExploding }) => {
    const socket = useContext(SocketContext)

    const [currentBet, setCurrentBet] = useState(0)
    const [fetchUserVersion, setFetchUserVersion] = useState(0);

    const [sfxVolume, setSFXVolume] = useLocalStorage({
        key: 'sfx-volume',
        defaultValue: '30',
    });

    const winSFX = useRef(new Audio('/sounds/success_bell.mp3'));

    useEffect(() => {
        winSFX.current.volume = Number(sfxVolume) / 100;
    }, [sfxVolume]);

    const playWinSFX = () => {
        winSFX.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    const [flippingGraphicIsHeads, setFlippingGraphicIsHeads] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            setFlippingGraphicIsHeads(!flippingGraphicIsHeads)
        }, 150);

        return () => clearInterval(interval); // Clean up on unmount
    }, [flippingGraphicIsHeads]);

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    let prettyNumberOptions = {
        'justification': 'C',
        'shortFormat': true,
        'shortFormatMinValue': 10000,
        'shortFormatPrecision': 2
    }

    useEffect(() => {
        if (socket) {
            socket.on('receive_game_instance_update', (data) => {
                if (data && data.gameInstance) {
                    setCurrentGameInstance(data.gameInstance)
                    if (data.updatedUser) {
                        if (!data.gameInstance.gameState.flipping &&
                            data.gameInstance.gameState.heads === data.gameInstance.gameState.playerStates[user._id.toString()].heads) {
                            playWinSFX()
                            setIsExploding(true)
                        } else {
                            setIsExploding(false)
                        }
                        setUser(data.updatedUser)
                        setFetchUserVersion(prev => prev + 1);
                    }
                }
            })
            socket.on('sent_game_consent_request', (data) => {
                setCurrentBet(0);
            })
        }
        return () => {
            if (socket) {
                socket.off('receive_game_instance_update')
                socket.off('sent_game_consent_request')
            }
        }
    }, [socket]);

    const startGame = (heads) => {
        if (socket) {
            if (currentBet) {
                socket.emit('send_game_consent_request', {
                    action: 'initiate_coin_flip',
                    payload: {
                        bet: currentBet,
                        [user._id]: { heads: heads, bet: currentBet },
                        suppressNotifications: currentGameInstance && currentGameInstance.players && currentGameInstance.players.length === 1
                    },
                    message: `Would you like to flip a coin with bet ${currentBet}?`
                })
            }
        }
    };

    const leaveGame = () => {
        if (socket) {
            socket.emit('leave_game')
        }
    };

    if (!currentGameInstance || !currentGameInstance.gameState || !currentGameInstance.players || currentGameInstance.players.length < 1) {
        return (
            <Flex p='lg' justify="center" align="center">
                <Loader />
            </Flex>
        )
    } else {
        //console.log(currentGameInstance)
    }

    const isPlayerOne = currentGameInstance.players[0].toString() === user._id.toString();
    let playerIndex = 0;

    return (
        <div style={{ width: '100%' }}>
            <Center sx={{ width: '100%' }}>
                <Stack sx={{ width: '100%' }}>
                    <Flex sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                        textAlign: 'center',
                        padding: theme.spacing.sm,
                        borderRadius: theme.radius.md,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: '100%'
                    })}>
                        <Center sx={{ width: '100%', height: '100%' }}>
                            <SimpleGrid cols={isNarrow ?
                                (currentGameInstance.players.length < 2 ? 1 : 2) :
                                (currentGameInstance.players.length < 5 ? currentGameInstance.players.length : 5)}
                                sx={{ width: '100%' }}>
                                {
                                    //show playerdisplay for each player
                                    currentGameInstance.players.map((player, index) => {
                                        return (
                                            <PlayerDisplay key={index} index={index} userId={player} gameInstance={currentGameInstance} user={user}
                                                fetchUserVersion={fetchUserVersion} />
                                        )
                                    })
                                }
                            </SimpleGrid>
                        </Center>
                    </Flex>
                    <Center sx={{ width: '100%' }}>
                        <Flex justify='center' sx={(theme) => ({
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                            textAlign: 'center',
                            padding: theme.spacing.xl,
                            borderRadius: theme.radius.md,
                            display: 'flex',
                            flexDirection: 'row',
                            width: 250,
                        })}>
                            <Flex justify='center' sx={{
                                maxWidth: 250,
                                width: '100%',
                                animation: currentGameInstance.gameState.flipping ?
                                    `${flip} 0.3s infinite linear` : `${settle} 0.2s ease-out`, // Apply the flip animation or settle animation
                            }}>
                                {
                                    currentGameInstance.gameState.flipping ? (
                                        //if heads, show heads coin, else show tails coin
                                        flippingGraphicIsHeads ?
                                            <Image src='/images/games/coinflip/quarter_coin_heads.svg' sx={{ width: '100%', height: '100%' }} />
                                            :
                                            <Image src='/images/games/coinflip/quarter_coin_tails.svg' sx={{ width: '100%', height: '100%' }} />
                                    ) : (
                                        currentGameInstance.gameState.heads ?
                                            <Image src='/images/games/coinflip/quarter_coin_heads.svg' sx={{ width: '100%', height: '100%' }} />
                                            :
                                            <Image src='/images/games/coinflip/quarter_coin_tails.svg' sx={{ width: '100%', height: '100%' }} />
                                    )
                                }
                            </Flex>
                        </Flex>
                    </Center>
                    {
                        //if we are at the start or end, show the leave game button
                        (!currentGameInstance.gameState.flipping) ?
                            (
                                <Flex sx={(theme) => ({
                                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                                    textAlign: 'center',
                                    padding: theme.spacing.sm,
                                    borderRadius: theme.radius.md,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    marginTop: 6,
                                    width: '100%',
                                    height: '100%'
                                })}>
                                    <Grid p='xs' columns={isNarrow ? 1 : 20} dir='vertical' sx={{ width: '100%' }}>
                                        {
                                            isPlayerOne &&
                                            <Grid.Col p='xs' span={isNarrow ? 1 : 9} offset={isNarrow ? 0 : 0}>
                                                <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                    <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                        <Flex direction='row' justify='center' align='center'>
                                                            <Text size='sm' weight={500} pr='xs'>
                                                                Bet:
                                                            </Text>
                                                            <Slider
                                                                value={currentBet}
                                                                onChange={(value) => {
                                                                    setCurrentBet(value)
                                                                }}
                                                                max={user.funds}
                                                                step={5}
                                                                label={<Text>Bet: {currentBet}</Text>}
                                                                color='dark'
                                                                marks={[
                                                                    { value: `${user.funds * 0.25}` },
                                                                    { value: `${user.funds * 0.50}` },
                                                                    { value: `${user.funds * 0.75}` },
                                                                ]}
                                                                sx={{ width: '100%' }}
                                                            />
                                                        </Flex>
                                                        <NumberInput value={currentBet} onChange={setCurrentBet} min={0} max={user.funds} step={5} />
                                                    </Stack>
                                                    <Button fullWidth
                                                        onClick={() => startGame(true)} size='md' sx={{ minHeight: 40 }}>
                                                        Heads
                                                    </Button>
                                                    <Button fullWidth
                                                        onClick={() => startGame(false)} size='md' sx={{ minHeight: 40 }}>
                                                        Tails
                                                    </Button>
                                                </Stack>
                                            </Grid.Col>
                                        }
                                        <Grid.Col p='xs' span={isPlayerOne ? (isNarrow ? 1 : 9) : (isNarrow ? 1 : 20)} offset={isPlayerOne ? (isNarrow ? 0 : 2) : (isNarrow ? 0 : 0)}>
                                            <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                <Button fullWidth color='red'
                                                    onClick={leaveGame} size='md' sx={{ minHeight: 40 }}>
                                                    Leave
                                                </Button>
                                            </Stack >
                                        </Grid.Col>
                                    </Grid>
                                </Flex>
                            ) : (
                                <Flex sx={(theme) => ({
                                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                                    textAlign: 'center',
                                    padding: theme.spacing.sm,
                                    borderRadius: theme.radius.md,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    marginTop: 6,
                                    width: '100%',
                                    height: '100%'
                                })}>

                                </Flex>
                            )
                    }
                </Stack>
            </Center>
        </div >
    )

}

export default CoinFlipGame