import React, { useEffect, useState, useContext } from 'react'
import { Center, Flex, Loader, UnstyledButton } from '@mantine/core'

import { showNotification } from '@mantine/notifications'

import ConnectFourPiece from './ConnectFourPiece'
import ConnectFourSquare from './ConnectFourSquare'

import { AiFillCheckCircle } from 'react-icons/ai'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

const ConnectFourBoard = ({ user, refreshUser, setUser, currentGameInstance, setCurrentGameInstance, tileHighlights }) => {
    const socket = useContext(SocketContext);

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    const [highlightedColumn, setHighlightedColumn] = useState(null)

    if (!currentGameInstance || !currentGameInstance.gameState) {
        return (
            <Flex p='lg' justify="center" align="center">
                <Loader />
            </Flex>
        )
    } else {
        //console.log(currentGameInstance)
    }

    const highlightTile = (rowIndex, squareIndex) => {
        //check if tile highlights contains this square
        if (tileHighlights) {
            for (let i = 0; i < tileHighlights.length; i++) {
                if (tileHighlights[i].col === squareIndex && tileHighlights[i].row === rowIndex) {
                    return true
                }
            }
        }
        return false
    };

    const getSquareColor = (rowIndex, squareIndex) => {
        if (rowIndex % 2 === 0) {
            if (squareIndex % 2 === 0) {
                return 'gray'
            } else {
                return 'black'
            }
        } else {
            if (squareIndex % 2 === 0) {
                return 'black'
            } else {
                return 'gray'
            }
        }
    };

    const getPieceColor = (piece) => {
        if (piece === 1 || piece === 3) {
            return 'yellow'
        } else {
            return 'red'
        }
    };

    const board = () => {
        return (
            [...Array(currentGameInstance.gameState.currentBoard[0].length)].map((_, columnIndex) => {
                return (
                    <UnstyledButton onClick={() => {
                        if (socket) {
                            socket.emit('perform_connect_four_action', { action: `${columnIndex}` })
                        }
                    }} onMouseOver={() => {
                        setHighlightedColumn(columnIndex)
                    }} onMouseOut={() => {
                        setHighlightedColumn(null)
                    }}
                        sx={(theme) => ({
                            width: '100%',
                        })} key={columnIndex}
                    >
                        <Flex key={columnIndex} direction='column'>
                            {
                                [...currentGameInstance.gameState.currentBoard].reverse().map((row, rowIndex) => {
                                    const square = row[columnIndex];
                                    return (
                                        <ConnectFourSquare gameInstance={currentGameInstance} user={user} color={getSquareColor(rowIndex, columnIndex)}
                                            x={columnIndex} y={rowIndex} selectHighlight={highlightedColumn === columnIndex}
                                            highlight={highlightTile(5 - rowIndex, columnIndex)} key={rowIndex}>
                                            {
                                                square !== 0 ?
                                                    <ConnectFourPiece gameInstance={currentGameInstance} user={user}
                                                        x={columnIndex} y={rowIndex} color={getPieceColor(square)} />
                                                    :
                                                    null
                                            }
                                        </ConnectFourSquare>
                                    )
                                })
                            }
                        </Flex>
                    </UnstyledButton>
                )
            }
            )
        )
    };

    return (
        <Center>
            {
                board()
            }
        </Center>
    )
}

export default ConnectFourBoard