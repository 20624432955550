import React, { useEffect, useState, useContext } from 'react'
import { Container, Flex, Grid, Stack, Tabs } from '@mantine/core'
import PlayerHomeHeader from '../PlayerHomeHeader/PlayerHomeHeader'
import TopUserList from './TopUserList'

import { SocketContext } from '../../context/socket'

const LeaderboardMenu = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    const [topUsers, setTopUsers] = useState([])
    const [topGameTypeUsers, setTopGameTypeUsers] = useState({})

    const [games, setGames] = useState([])

    useEffect(() => {
        if (games.length > 0) {
            games.forEach((game) => {
                if (socket) {
                    socket.emit("get_top_users_for_game_type", { gameType: game.uniqueName })
                }
            })
        }
    }, [games]);

    useEffect(() => {
        if (socket) {

            if (!games || games.length === 0) {
                socket.emit('get_all_games')
            }

            socket.on('got_all_games', (data) => {
                if (data && data.result && data.result.games) {
                    //sort games by underContruction, then by category, then by name
                    const sortedGames = data.result.games.sort((a, b) => {
                        if (a.underConstruction && !b.underConstruction) {
                            return 1
                        } else if (!a.underConstruction && b.underConstruction) {
                            return -1
                        } else if (a.category > b.category) {
                            return 1
                        } else if (a.category < b.category) {
                            return -1
                        } else if (a.name > b.name) {
                            return 1
                        } else if (a.name < b.name) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                    const filteredGames = sortedGames.filter((game) => game.underConstruction === false)
                    setGames(filteredGames)
                }
            });

            socket.emit("get_top_users_by_funds")
            socket.on('got_top_users_by_funds', (data) => {
                if (data && data.result && data.result.topUsers) {
                    setTopUsers(data.result.topUsers.sort((a, b) => a.currency - b.currency))
                }
            })

            socket.on('got_top_users_for_game_type', (data) => {
                if (data && data.result && data.result.topUsers) {
                    setTopGameTypeUsers(prevTopGameTypeUsers => ({
                        ...prevTopGameTypeUsers,
                        [data.result.gameType]: data.result.topUsers
                    }));
                }
            })
        }
        return () => {
            if (socket) {
                socket.off('got_top_users_by_funds')
                socket.off('got_top_users_for_game_type')
            }
        }
    }, [socket]);

    const Loader = () => {
        return (
            <div>
                <Grid columns={3} sx={{ width: '100%' }}>
                    <Grid.Col span={1} offset={1} >
                        <Loader variant='dots' />
                    </Grid.Col>
                </Grid>
            </div>
        )
    }

    return (
        <Flex p='md' sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
            textAlign: 'center',
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        })}>
            <Stack sx={{ width: '100%' }}>
                <Tabs variant='default' color='indigo' defaultValue='top-by-currency'>
                    <Tabs.List>
                        <Tabs.Tab value='top-by-currency'>
                            Funds
                        </Tabs.Tab>
                        {
                            games.map((game) => {
                                return (
                                    <Tabs.Tab key={game.uniqueName} value={`top-${game.uniqueName}`}>
                                        {game.name}
                                    </Tabs.Tab>
                                )
                            })
                        }
                    </Tabs.List>
                    <Tabs.Panel value='top-by-currency'>
                        <TopUserList
                            topUsers={topUsers} />
                    </Tabs.Panel>
                    {
                        games.map((game) => {
                            return (
                                <Tabs.Panel key={game.uniqueName} value={`top-${game.uniqueName}`}>
                                    <TopUserList
                                        topUsers={topGameTypeUsers[game.uniqueName] ? topGameTypeUsers[game.uniqueName] : []}
                                        gameType={game.uniqueName} />
                                </Tabs.Panel>
                            )
                        })
                    }
                </Tabs>
            </Stack>
        </Flex>
    )

}

export default LeaderboardMenu