import { AUTH, FETCH_USER } from '../constants/actionTypes'
import * as api from '../api'

export const signin = (formData, callback) => async (dispatch) => {
    try {
        const { data } = await api.signin(formData)
        dispatch({ type: AUTH, data })
        if (callback) callback(data)
        window.location.reload()
    } catch (error) {
        if (callback) callback(null)
        console.log(error)
    }
}

export const googlesignin = (formData, callback) => async (dispatch) => {
    try {
        const { data } = await api.googlesignin(formData)
        dispatch({ type: AUTH, data })
        if (callback) callback(data)
        window.location.reload()
    } catch (error) {
        if (callback) callback(null)
        console.log(error)
    }
}

export const signup = (formData, callback) => async (dispatch) => {
    try {
        const { data } = await api.signup(formData)
        dispatch({ type: AUTH, data })
        if (callback) callback(data)
        window.location.reload()
    } catch (error) {
        if (callback) callback(null)
        console.log(error)
    }
}

export const fetchUser = (id, callback) => async (dispatch) => {
    try {
        const { data } = await api.fetchUser(id)
        dispatch({ type: FETCH_USER, data })
        if (callback) callback(data)
    } catch (error) {
        console.log(error)
    }
}
