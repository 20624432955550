import React, { useEffect, useState, useContext, useRef } from 'react'
import { Badge, Button, Card, Center, Container, Divider, Flex, Grid, Image, Loader, Modal, NumberInput, Paper, SimpleGrid, Slider, Stack, Text } from '@mantine/core'

import { showNotification } from '@mantine/notifications'
import { useLocalStorage } from '@mantine/hooks'

import NumericLabel from 'react-pretty-numbers'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

import { keyframes } from '@emotion/react';
// Define the scroll animation
const scroll = keyframes`
  0% { transform: translateY(100%); }
  100% { transform: translateY(-100%); }
`;
const scrollDown = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(100%); }
`;

const SlotMachineGame = ({ user, refreshUser, setUser, currentGameInstance, setCurrentGameInstance, setIsExploding }) => {
    const socket = useContext(SocketContext)

    const [currentBet, setCurrentBet] = useState(0)
    const [betLines, setBetLines] = useState(1);

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    const [reelOffset, setReelOffset] = useState([0, 0, 0]);

    const [sfxVolume, setSFXVolume] = useLocalStorage({
        key: 'sfx-volume',
        defaultValue: '30',
    });

    const winSFX = useRef(new Audio('/sounds/success_bell.mp3'));
    const stopSFX = useRef(new Audio('/sounds/stop.mp3'));

    useEffect(() => {
        winSFX.current.volume = Number(sfxVolume) / 100;
        stopSFX.current.volume = Number(sfxVolume) / 100;
    }, [sfxVolume]);

    const playWinSFX = () => {
        winSFX.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    const playStopSFX = () => {
        stopSFX.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    let prettyNumberOptions = {
        'justification': 'C',
        'shortFormat': true,
        'shortFormatMinValue': 10000,
        'shortFormatPrecision': 2
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setReelOffset((oldOffsets) => oldOffsets.map((offset, index) =>
                currentGameInstance.gameState.spinStates[index] ? (offset + (index % 2 === 0 ? -2 : 2)) % currentGameInstance.gameState.reel.length : offset
            ));
        }, 150); // Change offset every 100 ms

        return () => clearInterval(interval); // Clean up on unmount
    }, [currentGameInstance.gameState.spinStates, currentGameInstance.gameState.reel.length]);


    useEffect(() => {
        if (socket) {
            socket.on('performed_slots_action', (data) => {
                if (data) {
                    if (data.result) {
                        if (data.result.error) {
                            showNotification({
                                title: 'Error',
                                message: data.result.error,
                                color: 'red'
                            })
                        }
                        if (data.result.updatedGameInstance) {
                            setCurrentGameInstance(data.result.updatedGameInstance)
                        }
                    }
                }
            })
            socket.on('receive_game_instance_update', (data) => {
                if (data && data.gameInstance) {
                    setCurrentGameInstance(data.gameInstance)
                    if (data.updatedUser) {
                        setUser(data.updatedUser)
                    }
                    playStopSFX()
                    if (data.gameInstance.gameState.hasWon) {
                        setIsExploding(true)
                        playWinSFX()
                    } else {
                        setIsExploding(false)
                    }
                }
            })
        }
        return () => {
            if (socket) {
                socket.off('performed_slots_action')
                socket.off('receive_game_instance_update')
            }
        }
    }, [socket]);

    const leaveGame = () => {
        if (socket) {
            socket.emit('leave_game')
        }
    };

    const spinButton = () => {
        if (socket) {
            socket.emit('perform_slots_action', { bet: currentBet, lines: betLines })
        }
    };

    const betUp = () => {
        if (currentBet < 100) {
            setCurrentBet(currentBet + 10)
        }
    };

    const betDown = () => {
        if (currentBet >= 10) {
            setCurrentBet(currentBet - 10)
        }
    };

    const incrementBetLines = () => {
        const newBetLines = betLines + 1
        if (newBetLines <= 5) {
            setBetLines(newBetLines)
        } else {
            setBetLines(1)
        }
    }

    if (!user || !currentGameInstance || !currentGameInstance.gameState || !currentGameInstance.players || currentGameInstance.players.length < 1) {
        return (
            <Flex p='lg' justify="center" align="center">
                <Loader />
            </Flex>
        )
    } else {
        //console.log(currentGameInstance)
    }

    const reelSize = isNarrow ? 80 : 120;
    const showLeaveButton = !currentGameInstance.gameState.spinStates[2]

    const getFundsString = () => {
        return (
            <Flex justify='center' align='center'>
                <Image width={16} src='/images/ui/dollar.svg' />
                &nbsp;&nbsp;
                <Text weight={400}>
                    <NumericLabel params={prettyNumberOptions}>
                        {user.funds}
                    </NumericLabel>
                </Text>
            </Flex>
        )
    };

    const getReelSymbolImagePath = (reelIndex) => {
        //if reelIndex is negative, add reel.length to it
        if (reelIndex < 0) {
            reelIndex = currentGameInstance.gameState.reel.length + reelIndex
        }
        reelIndex = reelIndex % currentGameInstance.gameState.reel.length
        return `/images/games/slots/${currentGameInstance.gameState.reel[reelIndex]}.svg`
    }

    const getPayoutDisplay = () => {
        /*
         const paytableThree = {
            "watermelon": 3,
            "cherries": 20,
            "lemon": 50,
            "bar": 100,
            "seven": 777,
            "treasure": 25000
        };
        */
        const paytable = {
            "watermelon": "3",
            "cherries": "20",
            "lemon": "50",
            "bar": "100",
            "seven": "777",
            "treasure": "25k"
        };
        const symbols = Object.keys(paytable)
        return (
            <SimpleGrid cols={3} spacing={0} p={0} sx={{ width: '100%' }}>
                {symbols.map((symbol, index) => (
                    <Flex key={index} justify='center' align='center' >
                        <Image src={`/images/games/slots/${symbol}.svg`} width={16} height={16} sx={{ marginRight: 5 }} />
                        <Text weight={600}>{paytable[symbol]}</Text>
                    </Flex>
                ))}
            </SimpleGrid>
        )
    }

    const BetLines = ({ betLines }) => (
        <svg style={{ position: 'absolute', width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
            {betLines > 0 && (
                <line x1="0" y1="50" x2="100" y2="50" stroke="rgba(255,0,0,0.3)" strokeWidth="1" />
            )}
            {betLines > 1 && (
                <line x1="0" y1="100" x2="100" y2="0" stroke="rgba(255,255,0,0.3)" strokeWidth="1" />
            )}
            {betLines > 2 && (
                <line x1="0" y1="0" x2="100" y2="100" stroke="rgba(255,255,0,0.3)" strokeWidth="1" />
            )}
            {betLines > 3 && (
                <line x1="0" y1="81.5" x2="100" y2="81.5" stroke="rgba(255,0,255,0.3)" strokeWidth="1" />
            )}
            {betLines > 4 && (
                <line x1="0" y1="18.5" x2="100" y2="18.5" stroke="rgba(255,0,255,0.3)" strokeWidth="1" />
            )}
        </svg>
    );

    const SpinFlex = ({ index }) => (
        <Center>
            <Stack direction='column' spacing={3}>
                {
                    [-3, -2, -1, 0, 1, 2, 3].map(offset => (
                        <Flex
                            key={index + offset}
                            direction='column'
                            justify='center'
                            align='center'
                            sx={(theme) => ({
                                width: reelSize,
                                height: reelSize, // Adjust the height to accommodate additional rows
                                backgroundColor: 'gray',
                                borderRadius: 12,
                                border: `6px ridge ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.dark[3]}`,
                                animation: currentGameInstance.gameState.spinStates[index] !== true ? 'none' :
                                    `${index % 2 === 0 ? scrollDown : scroll} 0.15s infinite linear`, // Apply the scroll animation
                            })}
                        >
                            <Image
                                src={getReelSymbolImagePath(currentGameInstance.gameState.reelStates[index] +
                                    (offset + (currentGameInstance.gameState.spinStates[index] !== true ? 0 : reelOffset[index])))}
                                width={reelSize * 0.5} height={reelSize * 0.5} />
                        </Flex>
                    ))
                }
            </Stack>
        </Center>
    );


    return (
        <div style={{ width: '100%' }}>
            <Flex sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                padding: theme.spacing.sm,
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '100%'
            })}>
                <Center sx={{ width: '100%' }}>
                    <Stack direction='column' spacing={3}>
                        <Paper shadow='md' sx={(theme) => ({
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
                            textAlign: 'center',
                            padding: theme.spacing.md,
                            borderRadius: theme.radius.md,
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 6,
                            width: '100%',
                            height: '100%',
                            gap: theme.spacing.xs
                        })}>
                            <Card shadow='md' p={4} withBorder sx={(theme) => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                                borderRadius: 16
                            })}>
                                {getPayoutDisplay()}
                            </Card>
                            <Card shadow='md' withBorder sx={(theme) => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                            })}>

                                <Center sx={{ width: (reelSize * 3) + 20, height: reelSize * 3 }}>
                                    <SimpleGrid cols={3} spacing='md' sx={{ width: '100%' }}>
                                        {[0, 1, 2].map(index => <SpinFlex key={index} index={index} />)}
                                    </SimpleGrid>
                                    {
                                        currentGameInstance.gameState.spinStates[2] !== true &&
                                        <BetLines betLines={betLines} />
                                    }
                                </Center>
                            </Card>
                            <Card shadow='md' withBorder sx={(theme) => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                                borderRadius: 16
                            })}>
                                <Flex direction='row' justify='center' align='center' sx={{ width: '100%' }}>
                                    <SimpleGrid cols={3} spacing='md' >
                                        {
                                            getFundsString()
                                        }
                                        <Badge color='green' p='md' >
                                            <Text size={isNarrow ? 'xs' : 'md'} weight={500} align='center' >
                                                <Flex direction='row' justify='center' align='center' >
                                                    <NumericLabel params={prettyNumberOptions}>
                                                        {currentBet}
                                                    </NumericLabel>
                                                </Flex>
                                            </Text>
                                        </Badge>
                                        <Badge color='yellow' p='md' >
                                            <Text size={isNarrow ? 'xs' : 'md'} weight={500} align='center' >
                                                {`x${betLines}`}
                                            </Text>
                                        </Badge>
                                    </SimpleGrid>
                                </Flex>
                            </Card>
                            <Card shadow='md' withBorder sx={(theme) => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
                            })}>
                                <Flex direction='row' justify='space-between' align='center' sx={{ width: '100%' }}>
                                    <SimpleGrid cols={2} spacing='md' sx={{ width: '100%' }}>
                                        <Button color='red' sx={{ width: '100%', minHeight: 70 }} onClick={betDown}>
                                            <Text size='lg' color='white' weight={500} align='center' sx={{ width: '100%' }}>
                                                -10
                                            </Text>
                                        </Button>
                                        <Button color='green' sx={{ width: '100%', minHeight: 70 }} onClick={betUp}>
                                            <Text size='lg' color='white' weight={500} align='center' sx={{ width: '100%' }}>
                                                +10
                                            </Text>
                                        </Button>
                                        <Button color='cyan' sx={{ width: '100%', minHeight: 70 }} onClick={incrementBetLines}>
                                            <Text size='lg' color='white' weight={500} align='center' sx={{ width: '100%' }}>
                                                LINES
                                            </Text>
                                        </Button>
                                        <Button color='blue' sx={{ width: '100%', minHeight: 70 }} onClick={spinButton}>
                                            <Text size='lg' color='white' weight={500} align='center' sx={{ width: '100%' }}>
                                                SPIN
                                            </Text>
                                        </Button>
                                    </SimpleGrid>
                                </Flex>
                            </Card>
                        </Paper>
                    </Stack>
                </Center>
            </Flex>
            {
                showLeaveButton &&
                <Flex sx={(theme) => ({
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                    textAlign: 'center',
                    padding: theme.spacing.sm,
                    borderRadius: theme.radius.md,
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 16,
                    width: '100%',
                    height: '100%'
                })}>
                    <Flex direction='row' justify='center' align='center' sx={{ width: '100%' }}>
                        <Button fullWidth color='red'
                            onClick={leaveGame}
                            size='md' sx={{ minHeight: 40, maxWidth: 350 }}>
                            Leave
                        </Button>
                    </Flex>
                </Flex>
            }
        </div >
    )

}

export default SlotMachineGame