import { Button, Flex, ScrollArea, Tabs, Text, Input, Card, Divider } from '@mantine/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import NewButtonBar from '../NewButtonBar/NewButtonBar'

import { SocketContext } from '../../context/socket'

const RightPanel = ({ user, setUser, refreshUser, showTopPanel }) => {
    const socket = useContext(SocketContext)

    const [gameChatConversation, setGameChatConversation] = useState(null);
    const [inputText, setInputText] = useState("");

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight)
    });

    useEffect(() => {
        if (socket && user && user.currentGame) {
            socket.emit("get_game_conversation");
        }
    }, [user]);

    const sortAndSetGameChatConversation = (conversation) => {
        if (conversation && conversation.messages) {
            conversation.messages.sort((a, b) => {
                return b.timestamp - a.timestamp;
            })
        }
        setGameChatConversation(conversation)
    }

    useEffect(() => {
        if (socket) {
            socket.on("got_game_conversation", (data) => {
                if (data && data.result && data.result.conversation) {
                    sortAndSetGameChatConversation(data.result.conversation)
                }
            })
            socket.on("posted_message_to_game_conversation", (data) => {
                if (data && data.result && data.result.conversation) {
                    sortAndSetGameChatConversation(data.result.conversation)
                }
            })
            socket.on("game_conversation_update", (data) => {
                if (data && data.updatedConversation) {
                    sortAndSetGameChatConversation(data.updatedConversation)
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("got_game_conversation")
                socket.off("posted_message_to_game_conversation")
                socket.off("game_conversation_update")
            }
        }
    }, [socket]);

    const handleSend = () => {
        if (socket && inputText.length > 0) {
            socket.emit("post_message_to_game_conversation", { message: inputText })
        }
        setInputText("");
    }

    const getDateFromTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');

        const amOrPm = hours >= 12 ? 'PM' : 'AM';

        if (hours === 0) {
            hours = 12;
        } else if (hours > 12) {
            hours -= 12;
        }

        return `${hours.toString()}:${minutes} ${amOrPm}`;
    }

    return (
        <Flex direction='column'>
            {
                showTopPanel &&
                <NewButtonBar user={user} />
            }
            <Flex p='xs' mt={16} sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : 'white',
                textAlign: 'center',
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            })}>
                <Tabs variant='default' defaultValue='game-chat' color='indigo' sx={{ width: '100%' }}>
                    <Tabs.List grow>
                        <Tabs.Tab value='game-chat'>
                            Game Chat
                        </Tabs.Tab>
                        <Tabs.Tab value='direct-messages' disabled>
                            DMs
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value='game-chat'>
                        <ScrollArea mt={16} mb={10} pr={6} offsetScrollbars type='always'
                            sx={{
                                width: '100%', height: `${windowHeight - 338}px`, overflow: 'visible'
                            }}
                            styles={{
                                scrollbar: {
                                    '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                                        backgroundColor: 'rgba(40, 40, 40, 100)',
                                    },
                                }
                            }}
                        >
                            {
                                user && user.currentGame ?
                                    <Flex direction='column' gap={10} sx={{ width: '100%' }}>
                                        {
                                            gameChatConversation && gameChatConversation.messages.length ?
                                                gameChatConversation.messages.map((message, index) => (
                                                    <Card key={index} sx={{ width: '100%' }}>
                                                        <Card.Section sx={{ width: '100%' }}>
                                                            <Flex direction='row' justify='space-between' sx={{ width: '100%' }}>
                                                                <Text p={10} align='left' color={message.sender !== user._id ? '' : 'cyan'}
                                                                    sx={{ width: '100%', wordBreak: 'break-word' }}>
                                                                    {`${message.senderUsername}`}
                                                                </Text>
                                                                <Text size={14} pt={10} align='right' color={message.sender !== user._id ? '' : 'cyan'}
                                                                    sx={{ width: '100%', wordBreak: 'break-word' }}>
                                                                    {getDateFromTimestamp(message.timestamp)}
                                                                </Text>
                                                            </Flex>
                                                        </Card.Section>
                                                        <Divider />
                                                        <Card.Section sx={{ width: '100%' }}>
                                                            <Text p={10} align='left' sx={{ width: '100%', wordBreak: 'break-word' }}>
                                                                {`${message.message}`}
                                                            </Text>
                                                        </Card.Section>
                                                    </Card>
                                                ))
                                                :
                                                <Card sx={{ width: '100%' }}>
                                                    <Text p={10} align='center'
                                                        sx={{ width: '100%', wordBreak: 'break-word' }}>
                                                        {`Send a message to start the conversation!`}
                                                    </Text>
                                                </Card>
                                        }
                                    </Flex>
                                    :
                                    <Flex direction='column' gap={10} sx={{ width: '100%' }}>
                                        <Card sx={{ width: '100%' }}>
                                            <Text p={10} align='center'
                                                sx={{ width: '100%', wordBreak: 'break-word' }}>
                                                {`Join a game to chat!`}
                                            </Text>
                                        </Card>
                                    </Flex>
                            }
                        </ScrollArea>
                        <Flex p={4} gap={10} >
                            <Input
                                value={inputText}
                                onChange={(event) => setInputText(event.currentTarget.value)}
                                sx={{ flexGrow: 1 }}
                            />
                            <Button onClick={handleSend}>
                                Send
                            </Button>
                        </Flex>
                    </Tabs.Panel>
                    <Tabs.Panel value='direct-messages'>
                        <ScrollArea mt={16} offsetScrollbars type='always' scrollbarSize={6}
                            sx={{
                                width: '100%', height: `${windowHeight - 286}px`, overflow: 'visible'
                            }}
                            styles={{
                                scrollbar: {
                                    '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                                        backgroundColor: 'rgba(40, 40, 40, 100)',
                                    },
                                }
                            }}
                        >
                            <Flex sx={{ width: '100%' }}>

                            </Flex>
                        </ScrollArea>
                    </Tabs.Panel>
                </Tabs>
            </Flex>
        </Flex>
    )
}

export default RightPanel
