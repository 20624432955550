import React, { useState } from 'react'
import { Group, ScrollArea, Stack, Card, Badge, Flex, Text, Image, Paper, Title, SimpleGrid } from '@mantine/core'
import NumericLabel from 'react-pretty-numbers'

const TopUserList = ({ topUsers, gameType }) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight)
    });

    let prettyNumberOptions = {
        'justification': 'C',
        'shortFormat': true,
        'shortFormatMinValue': 1000,
        'shortFormatPrecision': 2
    }

    let jobCount = 0

    return (
        <Paper p={10} shadow='md' sx={{ width: '100%', marginBottom: 10 }}>
            <ScrollArea offsetScrollbars type='always' sx={{ width: '100%', height: `${windowHeight - 227}px` }}>
                <Stack sx={{ marginRight: 10 }}>
                    {topUsers && topUsers.map((user, index) => (
                        <Card key={jobCount++} p='xs' shadow='md' sx={(theme) => ({
                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                        })}>
                            <SimpleGrid
                                sx={{ width: '100%', justifyContent: 'space-around' }}
                                cols={3}
                                spacing="lg"
                                breakpoints={[
                                    { maxWidth: 720, cols: 2, spacing: 'md' },
                                    { maxWidth: 500, cols: 1, spacing: 'sm' },
                                ]}>
                                <Flex justify='center' align='center'>
                                    {index === 0 &&
                                        <Image width={50} src='/images/ui/first_place.svg' />
                                    }
                                    {index === 1 &&
                                        <Image width={40} src='/images/ui/second_place.svg' />
                                    }
                                    {index === 2 &&
                                        <Image width={40} src='/images/ui/third_place.svg' />
                                    }
                                    {index > 2 &&
                                        <Title order={3}>
                                            {index + 1}
                                        </Title>
                                    }
                                </Flex>
                                <Flex justify='center' align='center'>
                                    <Badge size='lg' variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                                        <Text>
                                            {user.username}
                                        </Text>
                                    </Badge>
                                </Flex>

                                <Flex direction='column' justify='center' align='center'>
                                    <Badge radius='md' color='green' size='lg' variant='filled' sx={{
                                        boxShadow: "0px 0px 4px 1px rgba(0,0,0,0.6)",
                                    }}>
                                        <Text>
                                            {
                                                user.record && user.record[gameType] && user.record[gameType].wins !== undefined ?
                                                    `WR: ${Math.round((((user.record[gameType].wins / user.record[gameType].gamesPlayed) * 100) + Number.EPSILON) * 100) / 100}%`
                                                    :
                                                    user.funds
                                            }
                                        </Text>
                                    </Badge>
                                    {
                                        user.record && user.record[gameType] && user.record[gameType].wins !== undefined &&
                                        <Badge radius='md' color='green' size='lg' mt={6} variant='filled' sx={{
                                            boxShadow: "0px 0px 4px 1px rgba(0,0,0,0.6)",
                                        }}>
                                            <Text>
                                                {
                                                    user.record && user.record[gameType] && user.record[gameType].wins !== undefined ?
                                                        `$: ${Math.round(((user.record[gameType].totalWon - user.record[gameType].totalBet) + Number.EPSILON) * 100) / 100}`
                                                        :
                                                        ''
                                                }
                                            </Text>
                                        </Badge>
                                    }
                                </Flex>
                            </SimpleGrid>
                        </Card>
                    ))}
                </Stack>
            </ScrollArea>
        </Paper>
    )

}

export default TopUserList