import React from 'react'
import { Grid, Text, Container, Flex, Loader, Image } from '@mantine/core'
import { BsCoin } from 'react-icons/bs'
import PlayerAvatarSmall from '../PlayerAvatarSmall/PlayerAvatarSmall'
import { Link, useNavigate } from 'react-router-dom'
import NumericLabel from 'react-pretty-numbers'

const MainPlayerStatsHeader = ({ user }) => {
    const navigate = useNavigate()

    let prettyNumberOptions = {
        'justification': 'C',
        'shortFormat': true,
        'shortFormatMinValue': 10000,
        'shortFormatPrecision': 2
    }

    const getFundsString = () => {
        return (
            <Flex justify='center' align='center'>
                <Image width={16} src='/images/ui/dollar.svg' />
                &nbsp;&nbsp;
                <Text weight={600}>
                    <NumericLabel params={prettyNumberOptions}>
                        {user.funds}
                    </NumericLabel>
                </Text>
            </Flex>
        )
    }

    if (!user || !user._id) {
        return (
            <Flex sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                padding: theme.spacing.md,
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row'
            })}>
                <Grid columns={3} sx={{ width: '100%' }}>
                    <Grid.Col span={1} offset={1} >
                        <Loader variant='dots' />
                    </Grid.Col>
                </Grid>
            </Flex>
        )
    }

    return (
        <Flex sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
            textAlign: 'center',
            padding: theme.spacing.md,
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row'
        })}>
            <Grid columns={2} sx={{ width: '100%' }}>
                <Grid.Col span={1}
                    onClick={() => {
                        navigate(`/user/${user._id}`)
                    }}
                    sx={(theme) => ({
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        '&:hover': {
                            backgroundColor: theme.colors.dark[5],
                            borderRadius: theme.radius.md,
                        },
                    })}>
                    <Text weight={600} lineClamp={1} sx={{ wordBreak: 'break-all' }}>
                        {user.username}
                    </Text>
                </Grid.Col>
                <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {getFundsString()}
                </Grid.Col>
            </Grid>
        </Flex>
    )

}

export default MainPlayerStatsHeader