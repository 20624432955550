import { createStyles, Title, Text, Button, Container, Group, Flex, Stack } from '@mantine/core';
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const useStyles = createStyles((theme) => ({
        root: {
            paddingTop: 80,
            paddingBottom: 80,
        },

        label: {
            textAlign: 'center',
            fontWeight: 900,
            fontSize: 220,
            lineHeight: 1,
            marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

            [theme.fn.smallerThan('sm')]: {
                fontSize: 120,
            },
        },

        title: {
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            textAlign: 'center',
            fontWeight: 900,
            fontSize: 38,

            [theme.fn.smallerThan('sm')]: {
                fontSize: 32,
            },
        },

        description: {
            maxWidth: 500,
            margin: 'auto',
            marginTop: theme.spacing.xl,
            marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        },
    }));

    const navigate = useNavigate()

    const { classes } = useStyles();

    const homeButton = () => {
        navigate('/games')
    }

    return (
        <Stack className={classes.root}>
            <div className={classes.label}>404</div>
            <Title className={classes.title}>We couldn't find that page.</Title>
            <Text color="dimmed" size="lg" align="center" className={classes.description}>
                You may have mistyped the address, or the page has
                been moved to another URL.
            </Text>
            <Group position="center">
                <Button variant="subtle" size="md" onClick={homeButton} >
                    Back to Home
                </Button>
            </Group>
        </Stack>
    );
}

export default PageNotFound