import React, { useEffect, useState, useContext, useRef } from 'react'
import { Button, Card, Center, Container, Divider, Flex, Grid, Input, Loader, Menu, Select, SimpleGrid, Stack, Text, Title } from '@mantine/core'

import PlayerHomeHeader from '../PlayerHomeHeader/PlayerHomeHeader'
import UserDisplay from '../UserDisplay/UserDisplay'

import { showNotification } from '@mantine/notifications'
import { useLocalStorage } from '@mantine/hooks'
import { useWindowResize } from '../Utilities/useWindowResize'
import { useNavigate } from 'react-router-dom'

import { AiOutlineCheck } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io'

import { SocketContext } from '../../context/socket'

const Friends = ({ user, refreshUser, setUser, friends, sortAndSetFriends }) => {
    const socket = useContext(SocketContext)
    const navigate = useNavigate()

    const [friendRequests, setFriendRequests] = useState(user ? user.friendRequests : [])
    const [displayedFriends, setDisplayedFriends] = useState(null)

    const [friendDisplayValue, setFriendDisplayValue] = useLocalStorage({
        key: 'friend-display-filter',
        defaultValue: 'all',
        getInitialValueInEffect: true,
    });

    const [requestUsername, setRequestUsername] = useState('')
    const handleUsernameChange = (event) => {
        setRequestUsername(event.target.value)
    };

    const aspectRatio = useWindowResize();
    const isMedium = aspectRatio.width / aspectRatio.height < 1.2
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.65

    useEffect(() => {
        setFriendRequests(user.friendRequests)
    }, [user])

    useEffect(() => {
        if (!friends) {
            return
        }
        if (friendDisplayValue == "all") {
            setDisplayedFriends(friends)
        } else if (friendDisplayValue == "online") {
            setDisplayedFriends(friends.filter(friend => friend.isOnline))
        } else if (friendDisplayValue == "offline") {
            setDisplayedFriends(friends.filter(friend => !friend.isOnline))
        }
    }, [friendDisplayValue, friends])

    useEffect(() => {
        if (socket) {
            socket.emit("get_all_friends")
            socket.on("got_all_friends", (data) => {
                if (data && data.result && data.result.friends) {
                    //sort friends by username and online status. the top should be online friends alphabetically, then offline friends
                    let friends = [...data.result.friends]
                    sortAndSetFriends(friends)
                }
            })
            socket.on("declined_friend_request", (data) => {
                if (data && data.result && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                    setFriendRequests(data.result.updatedUser.friendRequests)
                }
            })
            socket.on("removed_friend", (data) => {
                if (data && data.result && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                    socket.emit("get_all_friends")
                }
            })
            socket.on("added_friend", (data) => {
                if (data && data.result && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                    setFriendRequests(data.result.updatedUser.friendRequests)
                    socket.emit("get_all_friends")
                }
            })
            socket.on("receive_friend_request", (data) => {
                if (data && data.receiver) {
                    setUser(data.receiver)
                    setFriendRequests(data.receiver.friendRequests)
                }
            })
            socket.on("friend_was_removed", (data) => {
                if (data && data.updatedUser) {
                    setUser(data.updatedUser)
                    socket.emit("get_all_friends")
                }
            })
            socket.on("got_new_friend", (data) => {
                if (data && data.updatedUser) {
                    setUser(data.updatedUser)
                    socket.emit("get_all_friends")
                }
            })
            socket.on("sent_friend_request", (data) => {
                if (data && data.result && data.result.updatedFriend) {
                    showNotification({
                        title: 'Friend request sent!',
                        message: `You sent a friend request to ${data.result.updatedFriend.username}`,
                        color: 'blue',
                    })
                } else {
                    showNotification({
                        title: 'Error!',
                        message: `Failed to send friend request.`,
                        color: 'red',
                    })
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("got_all_friends")
                socket.off("declined_friend_request")
                socket.off("removed_friend")
                socket.off("added_friend")
                socket.off("receive_friend_request")
                socket.off("friend_was_removed")
                socket.off("got_new_friend")
                socket.off("sent_friend_request")
            }
        }
    }, [socket]);

    const sendFriendRequest = (username) => {
        if (socket) {
            socket.emit('send_friend_request', { username })
            setRequestUsername('')
        }
    };

    const acceptFriendRequest = (userId) => {
        if (socket) {
            socket.emit('add_friend', { userId })
        }
    };

    const declineFriendRequest = (userId) => {
        if (socket) {
            socket.emit('decline_friend_request', { userId })
        }
    };

    const removeFriend = (userId) => {
        if (socket) {
            socket.emit('remove_friend', { userId })
        }
    };

    const inviteToGroup = (userId) => {
        if (socket) {
            socket.emit('invite_to_group', { userId })
        }
    };

    const sendGameInvite = (userId) => {
        if (socket) {
            socket.emit("invite_to_game", { userId })
        }
    };

    const Loader = () => {
        return (
            <div>
                <Grid columns={3} sx={{ width: '100%' }}>
                    <Grid.Col span={1} offset={1} >
                        <Loader variant='dots' />
                    </Grid.Col>
                </Grid>
            </div>
        )
    }

    if (!user) {
        return <Loader />
    }

    return (
        <Center sx={{ width: '100%' }}>
            <Grid gutter={16} columns={isNarrow ? 1 : 1} sx={{ width: '100%' }}>
                <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Input value={requestUsername} onChange={handleUsernameChange} placeholder="Username" sx={{ width: '100%' }} />
                </Grid.Col>
                <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button variant='outline' onClick={() => sendFriendRequest(requestUsername)} sx={{ width: '100%' }}>
                        Send Request
                    </Button>
                </Grid.Col>
                <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Divider sx={{ width: '100%' }} />
                </Grid.Col>
                <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <Stack sx={{ width: '100%' }}>
                        <SimpleGrid cols={isNarrow ? 1 : 1} sx={{ width: '100%' }}>
                            <Title size={16} sx={{ wordBreak: 'break-all' }}>
                                Friends
                            </Title>
                            <Select
                                placeholder="All"
                                value={friendDisplayValue}
                                onChange={setFriendDisplayValue}
                                data={[
                                    { value: 'all', label: 'All Friends' },
                                    { value: 'online', label: 'Online Friends' },
                                    { value: 'offline', label: 'Offline Friends' },
                                ]}
                                sx={{ width: '100%' }}
                            />
                        </SimpleGrid>
                        <Divider />
                        {
                            displayedFriends && displayedFriends.length > 0 ? (
                                displayedFriends.map((friend, index) => {
                                    return (
                                        <Menu key={index} shadow="md" position={isMedium ? 'top' : 'right'} withArrow offset={6} arrowSize={12}>
                                            <Menu.Target>
                                                <Card p={8} key={index} shadow='md'
                                                    sx={(theme) => ({
                                                        width: '100%',
                                                        '&:hover': {
                                                            backgroundColor: theme.colors.dark[5],
                                                        },
                                                    })}>
                                                    <Center>
                                                        <Text ml={6} weight={600} truncate='end' sx={{ zIndex: 20, width: '100%' }}>
                                                            <Flex justify='flex-start' align='center'>
                                                                <Flex justify='center' align='center'
                                                                    mr={14}
                                                                    sx={{
                                                                        width: 10, height: 10,
                                                                        backgroundColor: friend.isOnline ? 'green' : 'red',
                                                                        borderRadius: 999
                                                                    }} />
                                                                {`${friend.username}`}
                                                            </Flex>
                                                        </Text>
                                                    </Center>
                                                </Card>
                                            </Menu.Target>
                                            <Menu.Dropdown p={6}>
                                                <Menu.Item onClick={() => {
                                                    navigate(`/user/${friend._id}`)
                                                }}>
                                                    <Text size='xs' weight={700}>
                                                        View Profile
                                                    </Text>
                                                </Menu.Item>
                                                <Menu.Divider />
                                                <Menu.Item onClick={() => {
                                                    inviteToGroup(friend)
                                                }}>
                                                    <Text size='xs' weight={700}>
                                                        Group Invite
                                                    </Text>
                                                </Menu.Item>
                                                <Menu.Item onClick={() => {
                                                    sendGameInvite(friend)
                                                }}>
                                                    <Text size='xs' weight={700}>
                                                        Game Invite
                                                    </Text>
                                                </Menu.Item>
                                                <Menu.Item disabled>
                                                    <Text size='xs' weight={700}>
                                                        Send Message
                                                    </Text>
                                                </Menu.Item>
                                                <Menu.Divider />
                                                <Menu.Item color='red' onClick={() => {
                                                    removeFriend(friend)
                                                }}>
                                                    <Text size='xs' weight={700}>
                                                        Remove Friend
                                                    </Text>
                                                </Menu.Item>
                                            </Menu.Dropdown>
                                        </Menu>
                                    )
                                })
                            ) : (
                                <Text sx={{ wordBreak: 'break-all' }}>
                                    No friends to display
                                </Text>
                            )
                        }
                    </Stack>
                </Grid.Col>
                <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <Stack sx={{ width: '100%' }}>
                        <Divider />
                        <Title size={16} sx={{ wordBreak: 'break-all' }}>
                            Requests
                        </Title>
                        <Divider />
                        {
                            friendRequests && friendRequests.length > 0 ? (
                                friendRequests.map((friendRequest, index) => {
                                    return (
                                        <Card p={8} key={friendRequest} sx={{ width: '100%' }} shadow='md'>
                                            <Grid sx={{ width: '100%', height: '100%' }} columns={12}>
                                                <Grid.Col span={8}>
                                                    <UserDisplay userId={friendRequest} checkIsOnline={true} justifyOverride={'flex-start'} />
                                                </Grid.Col>
                                                <Grid.Col span={2}>
                                                    <Button mt={4} mb={4} p={0} variant='outline' color='green' onClick={() => acceptFriendRequest(friendRequest)} sx={{ width: '100%' }}>
                                                        <AiOutlineCheck />
                                                    </Button>
                                                </Grid.Col>
                                                <Grid.Col span={2}>
                                                    <Button mt={4} mb={4} p={0} variant='outline' color='red' onClick={() => declineFriendRequest(friendRequest)} sx={{ width: '100%' }}>
                                                        <IoMdClose />
                                                    </Button>
                                                </Grid.Col>
                                            </Grid>
                                        </Card>
                                    )
                                })
                            ) : (
                                <Text sx={{ wordBreak: 'break-all' }}>
                                    No friend requests
                                </Text>
                            )
                        }
                    </Stack>
                </Grid.Col>
            </Grid>
        </Center>
    )

}

export default Friends