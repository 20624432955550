import React, { useEffect, useState, useContext } from 'react'
import { Container, Flex, Grid, Image, Loader, Overlay, Stack, Text } from '@mantine/core'

import PlayerHomeHeader from '../PlayerHomeHeader/PlayerHomeHeader'
import PlayingCard from '../PlayingCard/PlayingCard'
import UserDisplay from '../UserDisplay/UserDisplay'

import { GiBootKick } from 'react-icons/gi'

import { SocketContext } from '../../context/socket'

const PokerSeat = ({ userId, index, gameInstance, user, fetchUserVersion }) => {
    const socket = useContext(SocketContext)

    const gameState = gameInstance.gameState || null
    let userState = null

    const userIsWinner = () => {
        return gameState && gameState.winners && gameState.winners.length !== 0 && gameState.winners.includes(userId);
    };

    const isYourTurn = () => {
        return gameState && gameState.turn && gameState.turn === userId
    };

    if (!userId) {
        return (
            <Grid.Col span={1} sx={{ height: 72, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Flex sx={(theme) => ({
                    border: `0px solid ${theme.colorScheme === 'dark' ? 'gray' : theme.colors.dark[6]}`,
                    background: `${theme.colorScheme === 'dark' ? 'rgb(28, 21, 1)' : 'rgb(200, 200, 200)'}`,
                    width: '100%', height: '100%', borderRadius: 60, position: 'relative', justifyContent: 'center',
                    alignContent: 'center', alignItems: 'center'
                })} >
                    <Text truncate='end' sx={{ zIndex: 2 }}>
                        Seat {index + 1}
                    </Text>
                </Flex>
            </Grid.Col>
        )
    }

    const loading = () => {
        return (
            <Grid.Col span={1} sx={{ height: 75, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Flex p='lg' justify="center" align="center">
                    <Loader />
                </Flex>
            </Grid.Col>
        )
    };

    if (!gameInstance || !gameState) {
        return (
            loading()
        )
    }

    userState = gameState.playerStates[userId]

    if (!userState || !user) {
        return (
            loading()
        )
    }

    const hideCards = () => {
        if (gameState.winners && gameState.winners.length > 0 && userState.hand && userState.hand[0] && userState.hand[0].suit !== null && userState.showingCards) {
            return false
        }
        //check if all players have folded except one
        const allPlayers = Object.keys(gameState.playerStates)
        let foldedPlayers = 0
        allPlayers.forEach(player => {
            if (gameState.playerStates[player].folded) {
                foldedPlayers++
            }
        });
        const allPlayersFoldedButOne = foldedPlayers === allPlayers.length - 1 && gameState && gameState.winners && gameState.winners.length > 0
        return userId !== user._id && (userState.folded || allPlayersFoldedButOne || !gameState || !gameState.winners || !gameState.winners.length)
    };

    const getChipImage = () => {
        if (gameState && gameState.dealer === userId) {
            return '/images/ui/dealer_chip.svg'
        }
        if (userState && userState.blind) {
            if (userState.blind === 'small') {
                return '/images/ui/sb_chip.svg'
            } else if (userState.blind === 'big') {
                return '/images/ui/bb_chip.svg'
            }
        }
        return null
    };

    const chipImage = getChipImage()

    //console.log(userState)

    return (
        <Grid.Col span={1} sx={{ minHeight: 75, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack sx={{ width: '100%' }}>
                <Flex sx={(theme) => ({
                    border: `0px solid ${theme.colorScheme === 'dark' ? 'gray' : theme.colors.dark[6]}`,
                    background: `${theme.colorScheme === 'dark' ? 'rgb(28, 21, 1)' : 'rgb(200, 200, 200)'}`,
                    width: '100%', minHeight: 56, borderRadius: 60, position: 'relative', justifyContent: 'center',
                    alignContent: 'center', alignItems: 'center', boxShadow: "0px 2px 8px 1px rgba(0,0,0,0.6)"
                })} >
                    {
                        !gameState.winners || gameState.winners.length === 0 ? (
                            isYourTurn() && (<Overlay opacity={0.3} zIndex={10} radius={55} color='yellow' />)
                        ) : (
                            gameState.winners.includes(userId) && (<Overlay opacity={0.3} zIndex={10} radius={55} color='gold' />)
                        )
                    }
                    {
                        chipImage && (
                            <Image src={chipImage} width={26} height={26} sx={{
                                position: 'absolute', bottom: '-8px', left: '0px', zIndex: 20,
                            }} />
                        )
                    }
                    {
                        gameInstance && gameInstance.kickVotes && gameInstance.kickVotes[userId] && (
                            <Flex sx={{ position: 'absolute', bottom: '4px', right: '10px', zIndex: 20 }} justify='center' align='center'>
                                <Text size={12}>
                                    {gameInstance.kickVotes[userId].length}
                                </Text>
                                &nbsp;
                                <GiBootKick size={20} />
                            </Flex>
                        )
                    }
                    <UserDisplay user={user} userId={userId} showFunds={true} fetchUserVersion={fetchUserVersion} useMenu={true} />
                </Flex>
                <Flex mt={-14} mb={-10} sx={{ width: '100%' }} justify='center' align='center'>
                    {
                        userState && userState.hand && userState.hand.length > 0 ? (
                            userState.hand.map((card, index) => {
                                return (
                                    <Flex p='xs' key={index} justify='center' align='center'>
                                        <PlayingCard suit={card.suit} value={card.value} scale={0.6} flipped={hideCards()} folded={userState.folded} />
                                    </Flex>
                                )
                            })
                        ) : (
                            <Flex>
                                <Flex p='xs' justify='center' align='center'>
                                    <PlayingCard suit={0} value={0} scale={0.6} flipped={true} folded={false} />
                                </Flex>
                                <Flex p='xs' justify='center' align='center'>
                                    <PlayingCard suit={0} value={0} scale={0.6} flipped={true} folded={false} />
                                </Flex>
                            </Flex>
                        )
                    }
                </Flex>
                {
                    userState && userState.currentScore && userState.currentScore.score !== 0 ? (
                        <Flex mt={-10} mb={-10} sx={{ width: '100%' }} justify='center' align='center'>
                            <Stack>
                                <Text variant='caption' color={userIsWinner() ? 'gold' : ''} sx={{ zIndex: 2 }}>
                                    {hideCards() ? 'Hidden' : userState.currentScore.name}
                                </Text>
                                <Text weight={600} mt={-14} color={userIsWinner() ? 'gold' : ''} variant='caption' sx={{ zIndex: 2 }}>
                                    {
                                        userIsWinner() ? (
                                            'Winner'
                                        ) : (
                                            userState && userState.folded ?
                                                'Folded'
                                                :
                                                userState && userState.allIn ?
                                                    `All In` + (userState.bet !== 0 ? `: ${userState.bet}` : '')
                                                    :
                                                    `Bet: ${userState.bet}`
                                        )
                                    }
                                </Text>
                            </Stack>
                        </Flex>
                    ) : (
                        <Flex mt={-10} mb={-10} sx={{ width: '100%' }} justify='center' align='center'>
                            <Stack>
                                <Text variant='caption' sx={{ zIndex: 2 }}>
                                    Hidden
                                </Text>
                                <Text weight={600} mt={-14} variant='caption' sx={{ zIndex: 2 }}>
                                    Waiting
                                </Text>
                            </Stack>
                        </Flex>
                    )
                }
            </Stack>
        </Grid.Col>
    )

}

export default PokerSeat