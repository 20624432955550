import React, { useContext, useState } from 'react'
import { Avatar, Button, Paper, Grid, Text, Container, TextInput, PasswordInput, Center, Stack, Flex } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { MdLock } from 'react-icons/md'
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode'
import { signin, googlesignin, signup } from '../../actions/auth'

const initState = { username: '', email: '', password: '', confirmPassword: '', isGoogleUser: false }

const Auth = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [isSignup, setIsSignup] = useState(false)
    const [formData, setFormData] = useState(initState)

    const [usernameError, setUsernameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    const dispatch = useDispatch()

    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isSignup) {
            if (formData.email.length === 0) {
                setEmailError('Email required')
                return
            }
            if (formData.username.length < 3) {
                setUsernameError('Must be at least 3 characters')
                return
            }
            if (formData.password.length < 8) {
                setPasswordError('Must be at least 8 characters')
                return
            }
            if (formData.password !== formData.confirmPassword) {
                setConfirmPasswordError('Passwords do not match')
                return
            }
            dispatch(signup(formData, (data) => {
                if (!data || !data.result) {
                    showNotification({
                        title: 'Sign Up Failed',
                        message: 'Something went wrong, please try again!',
                        color: 'red'
                    })
                }
            }))
        } else {
            dispatch(signin(formData, (data) => {
                if (!data || !data.result) {
                    showNotification({
                        title: 'Login Failed',
                        message: 'Something went wrong, please try again!',
                        color: 'red'
                    })
                }
            }))
        }
    }

    const handleChange = (e) => {
        switch (e.target.name) {
            case 'username':
                setUsernameError('')
                break;
            case 'password':
            case 'confirmPassword':
                setPasswordError('')
                setConfirmPasswordError('')
                break
            case 'email':
                setEmailError('')
                break
        }
        let currentInput = e.target.value
        if (currentInput.length > 100) {
            currentInput = currentInput.substring(0, 100)
        }
        setFormData({ ...formData, [e.target.name]: currentInput })
    }

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup)
        setShowPassword(false)
    }

    const googleSuccess = async (res) => {
        const resCopy = res
        try {
            const credential = resCopy.credential
            dispatch(googlesignin({ credential }, (data) => {
                if (!data || !data.result) {
                    showNotification({
                        title: 'Google Auth Failed!',
                        message: 'Something went wrong, please try again!',
                        color: 'red'
                    })
                }
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const googleFailure = () => {
        showNotification({
            title: 'Google Auth Failed!',
            message: 'Something went wrong, please try again!',
            color: 'red'
        })
    }

    return (
        <Flex component='main' sx={{ marginTop: 20, maxWidth: '100%' }}>
            <Center sx={{ width: '100%' }}>
                <Paper p="xl" shadow="md" withBorder sx={{ width: 280, display: 'flex', justifyContent: 'center' }} >
                    <Stack>
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                            <MdLock size='60' />
                        </Grid>
                        <form onSubmit={handleSubmit} style={{ padding: 4 }} >
                            <Stack p={0} sx={{ width: 230 }}>
                                <TextInput name='email' value={formData.email} label='Email Address' onChange={handleChange}
                                    withAsterisk={isSignup} error={emailError} />
                                {isSignup &&
                                    <TextInput name='username' value={formData.username} label='Username' onChange={handleChange}
                                        withAsterisk={isSignup} error={usernameError} />
                                }
                                <PasswordInput name='password' value={formData.password} label='Password' onChange={handleChange}
                                    visible={showPassword} onVisibilityChange={handleShowPassword}
                                    description="Min. 8 characters" error={passwordError} withAsterisk={isSignup} />
                                {isSignup &&
                                    <PasswordInput name='confirmPassword' value={formData.confirmPassword} label='Confirm Password' onChange={handleChange}
                                        visible={showPassword} onVisibilityChange={handleShowPassword}
                                        withAsterisk={isSignup} error={confirmPasswordError} />
                                }
                                <Button type='submit' variant='contained' color='primary' className='submit-auth' sx={{ height: 50, marginTop: 10 }}>
                                    {isSignup ? 'Sign Up' : 'Sign In'}
                                </Button>
                                <Button onClick={switchMode} sx={{ marginTop: 0, width: '100%' }}>
                                    {isSignup ? 'Have an account? Sign In!' : 'No account? Sign Up!'}
                                </Button>
                                <Text sx={{ marginTop: 0, marginBottom: 0, display: 'flex', justifyContent: 'center' }}>
                                    - or -
                                </Text>
                                <Center>
                                    <GoogleLogin
                                        onSuccess={googleSuccess}
                                        onError={googleFailure}
                                    />
                                </Center>
                            </Stack>
                        </form>
                    </Stack>
                </Paper>
            </Center>
        </Flex>
    )

}

export default Auth