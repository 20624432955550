import React, { useEffect, useState, useContext, useRef } from 'react'
import { Button, Card, Center, Container, Divider, Flex, Grid, Image, Input, Loader, Modal, ScrollArea, Select, Slider, Stack, Text, Title } from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import { FaLink } from 'react-icons/fa'

import PokerGame from '../PokerGame/PokerGame'
import CheckersGame from '../CheckersGame/CheckersGame'
import SlotMachineGame from '../SlotMachineGame/SlotMachineGame'
import CoinFlipGame from '../CoinFlipGame/CoinFlipGame'
import ConnectFourGame from '../ConnectFourGame/ConnectFourGame'
import ChessGame from '../ChessGame/ChessGame'
import GameSelectMenu from '../GameSelectMenu/GameSelectMenu'
import PublicGameListings from './PublicGameListings'

import ConfettiExplosion from 'react-confetti-explosion';

import { SocketContext } from '../../context/socket'
import { useDisclosure, useLocalStorage } from '@mantine/hooks'
import { useWindowResize } from '../Utilities/useWindowResize'
import UserDisplay from '../UserDisplay/UserDisplay'

const GameHomeMenu = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.65

    const [isExploding, setIsExploding] = useState(false);

    const [opened, { open, close }] = useDisclosure(false);
    const [consentRequest, setConsentRequest] = useState(null);
    const [initiatorConsentRequest, setInitiatorConsentRequest] = useState(null);
    const [selectedGame, setSelectedGame] = useState(null);
    const [publicGameTypeForListings, setPublicGameTypeForListings] = useState(null);

    const closeAndReset = () => {
        close()
        setConsentRequest(null)
        setInitiatorConsentRequest(null)
    }

    const defaultGameSettings = {
        lobbyType: 'invite',
        setLobbyType: (lobbyType) => {
            setCurrentGameSettings({ ...currentGameSettings, lobbyType })
        },
    };
    const [currentGameSettings, setCurrentGameSettings] = useState({ ...defaultGameSettings });

    const modalTimeout = useRef(null);

    const [currentGameInstance, setCurrentGameInstance] = useLocalStorage('currentGameInstance', null)

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight)
    });

    const playGame = (gameId) => {
        if (socket) {
            socket.emit('create_game', { gameId, gameSettings: currentGameSettings })
            clearSettingsModal()
            setPublicGameTypeForListings(null)
        }
    }

    const findGame = (gameType) => {
        setPublicGameTypeForListings(gameType)
        clearSettingsModal()
    }

    const clearSettingsModal = () => {
        closeAndReset()
        setSelectedGame(null)
        setCurrentGameSettings({ ...defaultGameSettings })
    }

    const copyLink = () => {
        if (!currentGameInstance) {
            return
        }

        const link = `${window.location.origin}/games/play/${currentGameInstance._id}`
        navigator.clipboard.writeText(link)
        showNotification({
            title: 'Link Copied',
            message: 'Link copied to clipboard!',
            color: 'blue'
        });
    }

    const [modalTimerSwitch, setModalTimerSwitch] = useState(false)

    useEffect(() => {
        if (initiatorConsentRequest || consentRequest) {
            const interval = setInterval(() => {
                setModalTimerSwitch(!modalTimerSwitch);
            }, 1000);

            //get time from consentRequest.timestamp
            //if time from now is greater than 30 seconds, close modal and reset
            const timestamp = initiatorConsentRequest ? initiatorConsentRequest.timestamp : consentRequest.timestamp
            const timeFromNow = Date.now() - timestamp
            if (timeFromNow > 30000) {
                closeAndReset()
            }

            //also close if request's rejections plus acceptances are equal to the number of players in the game
            if (initiatorConsentRequest && initiatorConsentRequest.rejecters && initiatorConsentRequest.consenters) {
                if (initiatorConsentRequest.rejecters.length + initiatorConsentRequest.consenters.length === currentGameInstance.players.length) {
                    closeAndReset()
                }
            }

            return () => clearInterval(interval); // Clean up on unmount
        }
    }, [initiatorConsentRequest, consentRequest, modalTimerSwitch]);

    useEffect(() => {
        if (selectedGame) {
            open()
        }
    }, [selectedGame]);

    const getGameInstance = () => {
        if (user && user.currentGame) {
            if (socket) {
                socket.emit("get_game_instance", { gameInstanceId: user.currentGame })
            }
        }
    }

    useEffect(() => {
        getGameInstance()
    }, [user]);

    useEffect(() => {
        if (socket) {
            socket.on("created_game", (data) => {
                if (data && data.result && data.result.gameInstance) {
                    setUser(data.result.updatedUser)
                    setCurrentGameInstance(data.result.gameInstance)

                    if (data.result.gameInstance.lobbyType === 'link') {
                        copyLink()
                    }
                }
            })
            socket.on("joined_game", (data) => {
                if (data && data.result && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                }
            })
            socket.on('left_game', (data) => {
                if (data && data.result) {
                    if (data.result.updatedUser) {
                        setUser(data.result.updatedUser)
                        setCurrentGameInstance(null)
                    }
                }
            })
            socket.on("got_game_instance", (data) => {
                if (data && data.result && data.result.gameInstance) {
                    setCurrentGameInstance(data.result.gameInstance)
                }
            })
            socket.on("got_kicked_from_game", (data) => {
                if (data && data.receiver) {
                    setCurrentGameInstance(null)
                    showNotification({
                        title: 'Kicked from Game',
                        message: `You were removed from the game!`,
                        color: 'red',
                    })
                    setUser(data.receiver)
                }
            })
            socket.on('receive_game_consent_request', (data) => {
                if (data && data.consentRequest) {
                    if (modalTimeout.current) {
                        clearTimeout(modalTimeout.current);
                    }

                    setConsentRequest(data.consentRequest);
                    open();

                    if (currentGameInstance) {
                        socket.emit("affirm_game_consent_request_delivery", {
                            gameInstanceId: currentGameInstance._id, consentRequestTimestamp: data.consentRequest.timestamp
                        })
                    }
                }
            })
            socket.on('consent_request_was_delivered', (data) => {
                if (data && data.consentRequest) {
                    setInitiatorConsentRequest(data.consentRequest);
                }
            })
            socket.on('sent_game_consent_request', (data) => {
                if (data && data.result && data.result.consentRequest) {
                    if (data.result.consentRequest.payload && data.result.consentRequest.payload.suppressNotifications) {
                        return
                    }
                    showNotification({
                        title: 'Request Sent',
                        message: 'Waiting for responses...',
                        color: 'blue'
                    })
                    setInitiatorConsentRequest(data.result.consentRequest);
                    open();
                } else {
                    showNotification({
                        title: 'Request Failed',
                        message: 'Failed to send request to players.',
                        color: 'red'
                    })
                }
            })
            socket.on('consent_request_was_rejected', (data) => {
                if (data && data.consentRequest) {
                    showNotification({
                        title: 'Request Rejected',
                        message: data.consentRequest.message,
                        color: 'red'
                    })
                    closeAndReset()
                }
            })
            socket.on('consent_request_was_updated', (data) => {
                if (data) {
                    if (data.consentRequest) {
                        setInitiatorConsentRequest(data.consentRequest);
                    } else {
                        closeAndReset()
                    }
                }
            })
            socket.on('receive_game_instance_update', (data) => {
                if (data && data.gameInstance) {
                    closeAndReset()
                    setCurrentGameInstance(data.gameInstance)
                }
            })
            socket.on('consent_request_was_destroyed', (data) => {
                if (data && data.consentRequest) {
                    closeAndReset()
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("created_game")
                socket.off("joined_game")
                socket.off('left_game')
                socket.off("got_game_instance")
                socket.off("got_kicked_from_game")
                socket.off('receive_game_consent_request')
                socket.off('sent_game_consent_request')
                socket.off('consent_request_was_rejected')
                socket.off('consent_request_was_delivered')
                socket.off('receive_game_instance_update')
                socket.off('consent_request_was_updated')
                socket.off('consent_request_was_destroyed')
            }

            if (modalTimeout.current) {
                clearTimeout(modalTimeout.current);
            }
        }
    }, [socket, initiatorConsentRequest]);

    const respondToConsentRequest = (action) => {
        if (socket) {
            socket.emit('respond_to_game_consent_request', { consentRequest, response: action })
        }
    };

    const destroyConsentRequest = () => {
        if (socket) {
            socket.emit('destroy_game_consent_request', { consentRequest: consentRequest ? consentRequest : initiatorConsentRequest })
            closeAndReset()
        }
    };

    const getCurrentGameComponent = () => {
        if (!currentGameInstance) {
            return (
                <Flex p='lg' justify="center" align="center">
                    <Loader />
                </Flex>
            )
        }
        switch (currentGameInstance.gameType) {
            case 'texasholdem':
                return (
                    <PokerGame user={user} refreshUser={refreshUser} setUser={setUser} setIsExploding={setIsExploding}
                        currentGameInstance={currentGameInstance} setCurrentGameInstance={setCurrentGameInstance} />
                )
            case 'checkers':
                return (
                    <CheckersGame user={user} refreshUser={refreshUser} setUser={setUser} setIsExploding={setIsExploding}
                        currentGameInstance={currentGameInstance} setCurrentGameInstance={setCurrentGameInstance} />
                )
            case 'slots':
                return (
                    <SlotMachineGame user={user} refreshUser={refreshUser} setUser={setUser} setIsExploding={setIsExploding}
                        currentGameInstance={currentGameInstance} setCurrentGameInstance={setCurrentGameInstance} />
                )
            case 'coinflip':
                return (
                    <CoinFlipGame user={user} refreshUser={refreshUser} setUser={setUser} setIsExploding={setIsExploding}
                        currentGameInstance={currentGameInstance} setCurrentGameInstance={setCurrentGameInstance} />
                )
            case 'connectfour':
                return (
                    <ConnectFourGame user={user} refreshUser={refreshUser} setUser={setUser} setIsExploding={setIsExploding}
                        currentGameInstance={currentGameInstance} setCurrentGameInstance={setCurrentGameInstance} />
                )
            case 'chess':
                return (
                    <ChessGame user={user} refreshUser={refreshUser} setUser={setUser} setIsExploding={setIsExploding}
                        currentGameInstance={currentGameInstance} setCurrentGameInstance={setCurrentGameInstance} />
                )
            default:
                break;
        }
        return null
    }

    const innerConsentModal = () => {
        return (
            <Stack direction='column' spacing='xs'>
                <Text size='sm' weight={500}>
                    {consentRequest && consentRequest.message}
                </Text>
                <Divider />
                {
                    consentRequest && consentRequest.action === 'initiate_coin_flip' ? (
                        <Flex direction='row' justify='center' align='center'>
                            <Button onClick={() => {
                                respondToConsentRequest('heads')
                                closeAndReset()
                            }
                            } color='blue' variant='light' size='sm' radius='md' style={{ marginRight: 5 }}>
                                Heads
                            </Button>
                            <Button onClick={() => {
                                respondToConsentRequest('tails')
                                closeAndReset()
                            }
                            } color='blue' variant='light' size='sm' radius='md' style={{ marginRight: 5 }}>
                                Tails
                            </Button>
                            <Button onClick={() => {
                                respondToConsentRequest('decline')
                                closeAndReset()
                            }
                            } color='red' variant='light' size='sm' radius='md' style={{ marginLeft: 5 }}>
                                Decline
                            </Button>
                        </Flex>
                    ) : (
                        <Flex direction='row' justify='center' align='center'>
                            <Button onClick={() => {
                                respondToConsentRequest('accept')
                                closeAndReset()
                            }
                            } color='blue' variant='light' size='sm' radius='md' style={{ marginRight: 5 }}>
                                Accept
                            </Button>
                            <Button onClick={() => {
                                respondToConsentRequest('decline')
                                closeAndReset()
                            }
                            } color='red' variant='light' size='sm' radius='md' style={{ marginLeft: 5 }}>
                                Decline
                            </Button>
                        </Flex>
                    )
                }
            </Stack>
        );
    };

    const innerInitiatorConsentModal = () => {
        if (!initiatorConsentRequest) {
            return null
        }

        return (
            <Stack direction='column' spacing='xs'>
                <Text size='sm' weight={500}>
                    {initiatorConsentRequest.message}
                </Text>
                <Divider />
                {
                    <Flex direction='column' justify='center' align='center' sx={{ width: '100%' }}>
                        <Grid columns={3} sx={{ width: '100%' }}>
                            {
                                currentGameInstance && currentGameInstance.players && currentGameInstance.players.length > 0 &&
                                currentGameInstance.players.map((player, index) => {
                                    if (player === user._id) {
                                        return null
                                    }
                                    const isInDeliveries =
                                        initiatorConsentRequest.deliveries &&
                                        initiatorConsentRequest.deliveries.includes(player);
                                    return (
                                        <Grid.Col key={index} span={3}>
                                            <Grid columns={3} sx={{ width: '100%' }}>
                                                {/* User Display */}
                                                <Grid.Col span={1}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <UserDisplay userId={player} />
                                                    </div>
                                                </Grid.Col>

                                                {/* In Deliveries Text */}
                                                <Grid.Col span={1}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Text size='sm'>
                                                            {
                                                                //check if in deliveries, if so, show delivered, else show if rejected or accepted
                                                                initiatorConsentRequest.consenters &&
                                                                    initiatorConsentRequest.consenters.includes(player) ?
                                                                    'Accepted' :
                                                                    initiatorConsentRequest.rejecters &&
                                                                        initiatorConsentRequest.rejecters.includes(player) ?
                                                                        'Rejected' :
                                                                        isInDeliveries ? 'Delivered' : 'Not Delivered'
                                                            }
                                                        </Text>
                                                    </div>
                                                </Grid.Col>

                                                {/* Resend Button */}
                                                <Grid.Col span={1}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Button onClick={() => {
                                                            if (socket) {
                                                                socket.emit('send_game_consent_request', {
                                                                    ...initiatorConsentRequest,
                                                                    toPlayer: player
                                                                })
                                                            }
                                                        }
                                                        } color='blue' variant='light' size='sm' radius='md' style={{ marginRight: 5 }}>
                                                            Resend
                                                        </Button>
                                                    </div>
                                                </Grid.Col>
                                            </Grid>
                                        </Grid.Col>
                                    )
                                })
                            }
                        </Grid>
                    </Flex>
                }
                <Divider />
                <Flex direction='row' justify='space-between' align='center'>
                    <Text size='sm' weight={400}>
                        {
                            //show time remaining
                            initiatorConsentRequest && initiatorConsentRequest.timestamp &&
                                (Date.now() - initiatorConsentRequest.timestamp) / 1000 < 30 ?
                                `Timeout: ${Math.floor((30000 - (Date.now() - initiatorConsentRequest.timestamp)) / 1000)} sec.` :
                                `Timeout: 0 sec.`
                        }
                    </Text>
                    <Button onClick={() => {
                        destroyConsentRequest()
                    }} color='red' variant='light' size='sm' radius='md' style={{ marginLeft: 5 }}>
                        Cancel
                    </Button>
                </Flex>
            </Stack>
        );
    };

    const gameSettingsModal = () => {
        return (
            <div>
                {
                    selectedGame &&
                    <Modal
                        opened={opened && selectedGame}
                        onClose={() => { clearSettingsModal() }}
                        closeOnClickOutside={true} withCloseButton={false} centered>
                        {
                            <Stack direction='column' spacing='xs' sx={{ width: '100%' }}>
                                <Flex direction='row' justify='center' align='center'>
                                    <Image height={100 * 0.8} src={selectedGame.imageSource} radius={10} sx={{ maxWidth: 130 * 0.8, width: '100%' }} />
                                    <Title ml={16} align='center' order={2} weight={500} sx={{ width: '100%' }}>
                                        {selectedGame.name}
                                    </Title>
                                </Flex>
                                <Divider size={4} />
                                <Container p={16} shadow='md' sx={(theme) => ({
                                    width: '100%',
                                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                                })}>
                                    <Stack>
                                        <Flex p='xs' direction='row' justify='space-between' align='center' gap='sm' sx={{ minHeight: 40, width: '100%' }}>
                                            <Text size='sm' weight={500}>
                                                Lobby Type
                                            </Text>
                                            <Divider orientation='vertical' />
                                            <Stack direction='row' spacing='sm'>
                                                <Select data={[
                                                    { value: 'invite', label: 'Invite Only' },
                                                    { value: 'public', label: 'Public' },
                                                    { value: 'friends', label: 'Friends Only' },
                                                    { value: 'link', label: 'Anyone With Link' },
                                                ]} placeholder='Lobby Type' value={currentGameSettings?.lobbyType} onChange={currentGameSettings?.setLobbyType} />
                                                {
                                                    currentGameSettings?.lobbyType === 'link' && false &&
                                                    <Button size='sm' color='blue' variant='light' radius='md' onClick={copyLink}>
                                                        Copy Link
                                                    </Button>
                                                }
                                            </Stack>
                                        </Flex>
                                        <Divider />
                                        <Flex p='xs' direction='row' justify='space-between' align='center' gap='md' sx={{ minHeight: 40, width: '100%' }}>
                                            <Button fullWidth size='lg' color='default' variant='filled'
                                                onClick={() => {
                                                    playGame(selectedGame._id);
                                                }}
                                            >
                                                Create Game
                                            </Button>
                                        </Flex>
                                    </Stack>
                                </Container>
                                <Divider size={4} />
                            </Stack>
                        }
                    </Modal >
                }
            </div>
        );
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ position: 'absolute', top: '40%', left: '50%' }}>
                {isExploding && <ConfettiExplosion />}
            </div>
            <Modal opened={opened && consentRequest} onClose={closeAndReset} title="Request" closeOnClickOutside={false} withCloseButton={false} centered>
                {innerConsentModal()}
            </Modal >
            <Modal opened={opened && initiatorConsentRequest} onClose={closeAndReset} title="Request" closeOnClickOutside={false} withCloseButton={false} centered>
                {innerInitiatorConsentModal()}
            </Modal >
            {
                user && user.currentGame ? (
                    <div>
                        <ScrollArea offsetScrollbars type='always' scrollbarSize={6} sx={{ width: '100%', height: `${windowHeight - 138}px`, overflow: 'visible' }}>
                            {
                                currentGameInstance && currentGameInstance.lobbyType != 'invite' && (
                                    <Button sx={{ position: 'absolute', top: 0, right: 0, zIndex: 200 }}
                                        size='sm' color='blue' variant='light' radius='md' onClick={copyLink}>
                                        <FaLink />
                                        {
                                            isNarrow ? null : (
                                                <Text sx={{ marginLeft: 6 }}>
                                                    Copy Link
                                                </Text>
                                            )
                                        }
                                    </Button>
                                )
                            }
                            {getCurrentGameComponent()}
                        </ScrollArea>
                    </div>
                ) : (
                    publicGameTypeForListings ? (
                        <PublicGameListings user={user} refreshUser={refreshUser} setUser={setUser} gameType={publicGameTypeForListings}
                            setPublicGameTypeForListings={setPublicGameTypeForListings} />
                    ) : (
                        <div>
                            <GameSelectMenu user={user} refreshUser={refreshUser} setUser={setUser} setSelectedGame={setSelectedGame}
                                setPublicGameTypeForListings={setPublicGameTypeForListings} findGame={findGame} playGame={playGame} />
                            {gameSettingsModal()}
                        </div>
                    )
                )
            }
        </div >
    )

}

export default GameHomeMenu