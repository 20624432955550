import React, { useEffect, useState, useContext } from 'react'
import { showNotification } from '@mantine/notifications'

import { SocketContext } from '../../context/socket'

const GlobalNotifications = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    useEffect(() => {
        if (socket) {
            socket.on("notify", (data) => {
                if (data) {
                    if (data.title && data.message && data.color) {
                        showNotification({
                            title: data.title,
                            message: data.message,
                            color: data.color,
                        })
                    }
                    if (data.user) {
                        setUser(data.user)
                    }
                }
            })
            socket.on("server_wide_refresh", () => {
                window.location.reload();
            })
            socket.on("server_wide_notification", (data) => {
                if (data && data.notification) {
                    showNotification({
                        title: data.notification.title,
                        message: data.notification.message,
                        color: data.notification.color,
                    })
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("notify")
                socket.off("server_wide_refresh")
                socket.off("server_wide_notification")
            }
        }
    }, [socket]);

    return (
        <></>
    )

}

export default GlobalNotifications