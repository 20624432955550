import React from 'react'
import { Paper, Text, Center, Flex, Divider, Stack, Title, Card } from '@mantine/core'

const Home = () => {

    return (
        <Center mb={100} sx={{ marginLeft: 20, marginRight: 20 }} >
            <Stack>
                <Paper sx={{ marginTop: 20, padding: 10 }} shadow="sm" p="md" withBorder>
                    <Flex justify='center' align='center' direction='column'>
                        <Text>
                            Welcome to Group Games!
                        </Text>
                        <Divider mt={6} mb={10} size='xs' sx={{ width: '100%' }} />
                        <Text>
                            Please log in to start playing.
                        </Text>
                    </Flex>
                </Paper>
                <Paper sx={{ marginTop: 20, padding: 10 }} shadow="sm" p="md" withBorder>
                    <Flex justify='center' align='center' direction='column'>
                        <Title size={20}>
                            Update Notes:
                        </Title>
                        <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                11/01/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Added Display and Controls for Response Requests
                            </Text>
                        </Card>
                        <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                10/14/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Added Public Lobbies
                                <br />
                                - Added Link Lobbies
                                <br />
                                - Added Resign Button to Chess, Checkers, and Connect Four
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                7/17/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Added User Profiles
                                <br />
                                - Added Game Chat
                                <br />
                                - More UI Improvements
                                <br />
                                - New UI now usable on mobile, but still not done
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                7/16/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Overhauled UI
                                <br />
                                - NOTE: New UI not yet optimized for mobile
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                7/5/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Introduced Chess
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                7/4/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Game Records and Stats
                                <br />
                                - Game Specific Leaderboards
                                <br />
                                - Introduced Connect Four
                                <br />
                                - Introduced Coin Flip
                                <br />
                                - Texas Hold'em visual tweaks
                                <br />
                                - Added top and bottom bet lines on Slots
                                <br />
                                - Rebalanced Slots, they're actually fixed now
                                <br />
                                - Option to now show only Online or Offline friends, etc
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                7/2/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Added sound effects & turn alerts to all games
                                <br />
                                - Added confetti effect on win
                                <br />
                                - Can now Show Cards at the end of the game in Texas Holdem
                                <br />
                                - Visual tweaks to Texas Holdem
                                <br />
                                - Added last move highlight in Checkers
                                <br />
                                - Rebalanced Slots, added more bet lines, added payouts display
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                6/31/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Introduced Slots, will be improving on it
                                <br />
                                - Improved UI of Checkers
                                <br />
                                - Added Checkers betting and mid-game raising
                                <br />
                                - Added tie detection to Checkers
                                <br />
                                - Added ability to request a draw in Checkers
                                <br />
                                - Added "Consent Request" system, allowing you to prompt other players to change something mid-game
                                <br />
                                - Added settings page, allowing users to change their usernames
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                6/29/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Introduced Checkers
                                <br />
                                - Removed Light Mode
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                6/26/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - UI fixed for mobile
                                <br />
                                - Introduced fund fountain, which gives you funds if you have less than 500
                                <br />
                                - Auto Check, Check/Fold, and Call Any buttons added to Texas Holdem
                                <br />
                                - Amount added to Call button in Texas Holdem
                                <br />
                                - Texas Holdem minimum bet is now big blind
                                <br />
                                - Fixed potential cheating issue with Texas Holdem
                            </Text>
                        </Card>
                        <Divider mt={14} mb={14} size='xs' sx={{ width: '100%' }} />
                        <Card shadow="sm" p="md" withBorder sx={{ width: '100%' }}>
                            <Text>
                                6/25/2023
                            </Text>
                            <Divider mt={8} mb={14} size='xs' sx={{ width: '100%' }} />
                            <Text>
                                - Visual tweaks
                                <br />
                                - Introduced ability to kick players from game based on vote
                                <br />
                                - Added in-game player context menu, allowing you to add friends, kick players, etc.
                                <br />
                                - System now removes you from game and group when you log out
                                <br />
                                - Fixed Texas Holdem hand scoring issue
                                <br />
                                - Fixed issue with progressing to end of Texas Holdem game when all other players are all in or folded
                                <br />
                                - Texas Holdem Bet bar now resets on action
                            </Text>
                        </Card>
                    </Flex>
                </Paper>
            </Stack>
        </Center>
    )

}

export default Home