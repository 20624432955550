import React, { useEffect, useState, useContext, useRef } from 'react'
import { Badge, Button, Center, Container, Divider, Flex, Grid, Image, Loader, Modal, NumberInput, Slider, Stack, Text } from '@mantine/core'

import { showNotification } from '@mantine/notifications'
import { useLocalStorage } from '@mantine/hooks'

import NumericLabel from 'react-pretty-numbers'

import { AiFillCheckCircle } from 'react-icons/ai'
import { BsXCircleFill } from 'react-icons/bs'

import { Chessboard, ChessPieceIcon } from 'kokopu-react'
import PlayerDisplay from './PlayerDisplay'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

const ChessGame = ({ user, refreshUser, setUser, currentGameInstance, setCurrentGameInstance, setIsExploding }) => {
    const socket = useContext(SocketContext)

    const [players, setPlayers] = useState(Array.from({ length: 2 }))
    const [currentBet, setCurrentBet] = useState(0)
    const [fetchUserVersion, setFetchUserVersion] = useState(0);

    const currentGameInstanceRef = useRef(currentGameInstance);

    useEffect(() => {
        currentGameInstanceRef.current = currentGameInstance;
    }, [currentGameInstance]);

    const [alertVolume, setAlertVolume] = useLocalStorage({
        key: 'alert-volume',
        defaultValue: '30',
        getInitialValueInEffect: true,
    });

    const [sfxVolume, setSFXVolume] = useLocalStorage({
        key: 'sfx-volume',
        defaultValue: '30',
    });

    const turnDing = useRef(new Audio('/sounds/bicycle-bell.mp3'));
    const winSFX = useRef(new Audio('/sounds/success_bell.mp3'));

    useEffect(() => {
        turnDing.current.volume = Number(alertVolume) / 100;
        winSFX.current.volume = Number(sfxVolume) / 100;
    }, [alertVolume, sfxVolume]);

    const playTurnDing = () => {
        turnDing.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    const playWinSFX = () => {
        winSFX.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    let prettyNumberOptions = {
        'justification': 'C',
        'shortFormat': true,
        'shortFormatMinValue': 10000,
        'shortFormatPrecision': 2
    }

    useEffect(() => {
        if (currentGameInstance && currentGameInstance.players) {
            let modifiedPlayers = Array.from({ length: 2 })
            currentGameInstance.players.forEach((player, index) => {
                modifiedPlayers[index] = player
            })
            setPlayers(modifiedPlayers)
            setFetchUserVersion(prev => prev + 1);
        }
    }, [currentGameInstance]);

    useEffect(() => {
        if (socket) {
            socket.on('performed_chess_action', (data) => {
                if (data) {
                    if (data.result) {
                        if (data.result.error) {
                            showNotification({
                                title: 'Error',
                                message: data.result.error,
                                color: 'red'
                            })
                        }
                    }
                }
            })
            socket.on('receive_game_instance_update', (data) => {
                if (data && data.gameInstance) {
                    setCurrentGameInstance(data.gameInstance)
                    if (data.updatedUser) {
                        setUser(data.updatedUser)
                    }
                    if (data.gameInstance.gameState.turn === user._id && data.gameInstance.gameState.movesTaken > 0 &&
                        (!data.gameInstance.gameState.winners || data.gameInstance.gameState.winners.length < 1)) {
                        playTurnDing()
                        setIsExploding(false)
                    } else {
                        if (data.gameInstance.gameState.winners && data.gameInstance.gameState.winners.length > 0) {
                            if (data.gameInstance.gameState.winners.includes(user._id)) {
                                setIsExploding(true)
                            }
                            playWinSFX()
                        }
                    }
                }
            })
            socket.on('sent_game_consent_request', (data) => {
                setCurrentBet(0);
            })
        }
        return () => {
            if (socket) {
                socket.off('performed_chess_action')
                socket.off('receive_game_instance_update')
                socket.off('sent_game_consent_request')
            }
        }
    }, [socket]);

    const startGame = () => {
        if (socket) {
            if (!currentBet) {
                socket.emit('send_game_consent_request', {
                    action: 'start_chess_game',
                    payload: null,
                    message: `Other player would like to start a game of chess with no bet.`
                })
            } else {
                socket.emit('send_game_consent_request', {
                    action: 'start_chess_game',
                    payload: { value: currentBet },
                    message: `Other player would like to start a game of chess with bet ${currentBet}.`
                })
            }
        }
    };

    const requestDraw = () => {
        if (socket) {
            socket.emit('send_game_consent_request', {
                action: 'request_chess_draw',
                payload: null,
                message: `Other player has proposed a draw.`
            })
        }
    };

    const requestBetRaise = () => {
        if (socket) {
            socket.emit('send_game_consent_request', {
                action: 'request_chess_bet_raise',
                payload: { value: currentBet },
                message: `Other player would like to raise the bet by ${currentBet}.`
            })
        }
    };

    const resignGame = () => {
        if (socket) {
            socket.emit('perform_chess_action', { action: 'resign' })
        }
    };

    const leaveGame = () => {
        if (socket) {
            socket.emit('leave_game')
        }
    };

    if (!currentGameInstance || !currentGameInstance.gameState || !currentGameInstance.players || currentGameInstance.players.length < 1) {
        return (
            <Flex p='lg' justify="center" align="center">
                <Loader />
            </Flex>
        )
    } else {
        //console.log(currentGameInstance)
    }

    const isPlayerOne = currentGameInstance.players[0].toString() === user._id.toString();
    let playerIndex = 0;

    return (
        <div style={{ width: '100%' }}>
            <Center>
                <Stack>
                    <Flex sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                        textAlign: 'center',
                        padding: theme.spacing.sm,
                        borderRadius: theme.radius.md,
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: '100%'
                    })}>
                        <Center sx={{ width: '100%', height: '100%' }}>
                            <Grid columns={5} sx={{ width: '100%' }}>
                                <Grid.Col span={2}>
                                    <PlayerDisplay key={playerIndex++} index={playerIndex} userId={players[0] || null} gameInstance={currentGameInstance} user={user}
                                        fetchUserVersion={fetchUserVersion} />
                                </Grid.Col>
                                <Grid.Col span={1}>
                                    <Flex direction='column' justify='center' align='center' sx={{ height: '100%' }}>
                                        <Badge color='orange' sx={{ marginBottom: 5 }}>
                                            <Flex justify='center' align='center'>
                                                <Image width={14} src='/images/ui/dollar.svg' sx={{ marginRight: 5 }} />
                                                <NumericLabel params={prettyNumberOptions}>
                                                    {currentGameInstance.gameState.pot}
                                                </NumericLabel>
                                            </Flex>
                                        </Badge>
                                        {
                                            currentGameInstance.gameState.winners && currentGameInstance.gameState.winners.length > 0 ?
                                                (
                                                    <Flex direction='column' justify='center' align='center'>
                                                        <Text size={14} weight={500} sx={{ marginBottom: 5 }}>
                                                            Winner
                                                        </Text>
                                                        <ChessPieceIcon size={36} type={[`${currentGameInstance.gameState.winners.includes(currentGameInstance.gameState.white) ?
                                                            'wk' :
                                                            'bk'
                                                            }`]} />
                                                        <Text size={12} weight={500} sx={{ marginTop: 5 }}>
                                                            {`${currentGameInstance.gameState.winners.length === 1 ?
                                                                (currentGameInstance.gameState.winners.includes(user._id) ? 'You' : 'Them')
                                                                :
                                                                'Draw'
                                                                }`
                                                            }
                                                        </Text>
                                                    </Flex>
                                                )
                                                :
                                                (
                                                    <Flex direction='column' justify='center' align='center'>
                                                        <Text size={14} weight={500} sx={{ marginBottom: 5 }}>
                                                            Turn
                                                        </Text>
                                                        <ChessPieceIcon size={36} type={[`${currentGameInstance.gameState.turn === currentGameInstance.gameState.white ?
                                                            'wk' :
                                                            'bk'
                                                            }`]} />
                                                        <Text size={12} weight={500} sx={{ marginTop: 5 }}>
                                                            {`${currentGameInstance.gameState.turn.toString() === user._id.toString() ? 'You' : 'Them'}`}
                                                        </Text>
                                                    </Flex>
                                                )
                                        }
                                    </Flex>
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <PlayerDisplay key={playerIndex++} index={playerIndex} userId={players[1] || null} gameInstance={currentGameInstance} user={user}
                                        fetchUserVersion={fetchUserVersion} />
                                </Grid.Col>
                            </Grid>
                        </Center>
                    </Flex>
                    <Flex sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                        textAlign: 'center',
                        padding: theme.spacing.xl,
                        borderRadius: theme.radius.md,
                        display: 'flex',
                        flexDirection: 'row',
                    })}>
                        <Chessboard
                            turnVisible={false}
                            coordinateVisible={isNarrow ? false : true}
                            squareSize={isNarrow ? 36 : 52}
                            colorset='dusk'
                            position={currentGameInstance.gameState.currentPosition}
                            flipped={currentGameInstance.gameState.white !== user._id}
                            interactionMode="playMoves"
                            onMovePlayed={move => {
                                if (socket) {
                                    socket.emit('perform_chess_action', { action: move })
                                }
                            }}
                        />
                    </Flex>
                    {
                        //if we are at the start or end, show the leave game button
                        (currentGameInstance.gameState.movesTaken === 0 ||
                            (currentGameInstance.gameState.winners && currentGameInstance.gameState.winners.length > 0)) ?
                            (
                                <Flex sx={(theme) => ({
                                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                                    textAlign: 'center',
                                    padding: theme.spacing.sm,
                                    borderRadius: theme.radius.md,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    marginTop: 6,
                                    width: '100%',
                                    height: '100%'
                                })}>
                                    <Grid p='xs' columns={isNarrow ? 1 : 20} dir='vertical' sx={{ width: '100%' }}>
                                        {
                                            isPlayerOne &&
                                            <Grid.Col p='xs' span={isNarrow ? 1 : 9} offset={isNarrow ? 0 : 0}>
                                                <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                    <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                        <Flex direction='row' justify='center' align='center'>
                                                            <Text size='sm' weight={500} pr='xs'>
                                                                Bet:
                                                            </Text>
                                                            <Slider
                                                                value={currentBet}
                                                                onChange={(value) => {
                                                                    setCurrentBet(value)
                                                                }}
                                                                max={user.funds}
                                                                step={5}
                                                                label={<Text>Bet: {currentBet}</Text>}
                                                                color='dark'
                                                                marks={[
                                                                    { value: `${user.funds * 0.25}` },
                                                                    { value: `${user.funds * 0.50}` },
                                                                    { value: `${user.funds * 0.75}` },
                                                                ]}
                                                                sx={{ width: '100%' }}
                                                            />
                                                        </Flex>
                                                        <NumberInput value={currentBet} onChange={setCurrentBet} min={0} max={user.funds} step={5} />
                                                    </Stack>
                                                    {
                                                        <Button fullWidth
                                                            onClick={startGame} size='md' sx={{ minHeight: 40 }}>
                                                            Start
                                                        </Button>
                                                    }
                                                </Stack>
                                            </Grid.Col>
                                        }
                                        <Grid.Col p='xs' span={isPlayerOne ? (isNarrow ? 1 : 9) : (isNarrow ? 1 : 20)} offset={isPlayerOne ? (isNarrow ? 0 : 2) : (isNarrow ? 0 : 0)}>
                                            <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                <Button fullWidth color='red'
                                                    onClick={leaveGame} size='md' sx={{ minHeight: 40 }}>
                                                    Leave
                                                </Button>
                                            </Stack >
                                        </Grid.Col>
                                    </Grid>
                                </Flex>
                            ) : (
                                <Flex sx={(theme) => ({
                                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                                    textAlign: 'center',
                                    padding: theme.spacing.sm,
                                    borderRadius: theme.radius.md,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    marginTop: 6,
                                    width: '100%',
                                    height: '100%'
                                })}>
                                    <Grid p='xs' columns={isNarrow ? 1 : 20} dir='vertical' sx={{ width: '100%' }}>
                                        {
                                            <Grid.Col p='xs' span={isNarrow ? 1 : 9} offset={isNarrow ? 0 : 0}>
                                                <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                    <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                        <Flex direction='row' justify='center' align='center'>
                                                            <Text size='sm' weight={500} pr='xs'>
                                                                Bet:
                                                            </Text>
                                                            <Slider
                                                                value={currentBet}
                                                                onChange={(value) => {
                                                                    setCurrentBet(value)
                                                                }}
                                                                max={user.funds}
                                                                step={5}
                                                                label={<Text>Bet: {currentBet}</Text>}
                                                                color='dark'
                                                                marks={[
                                                                    { value: `${user.funds * 0.25}` },
                                                                    { value: `${user.funds * 0.50}` },
                                                                    { value: `${user.funds * 0.75}` },
                                                                ]}
                                                                sx={{ width: '100%' }}
                                                            />
                                                        </Flex>
                                                        <NumberInput value={currentBet} onChange={setCurrentBet} min={0} max={user.funds} step={5} />
                                                    </Stack>
                                                    {
                                                        <Button fullWidth
                                                            onClick={requestBetRaise} size='md' sx={{ minHeight: 40 }}>
                                                            Raise Bet
                                                        </Button>
                                                    }
                                                </Stack>
                                            </Grid.Col>
                                        }
                                        <Grid.Col p='xs' span={isNarrow ? 1 : 9} offset={isNarrow ? 0 : 2}>
                                            <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                                <Button fullWidth color='red'
                                                    onClick={requestDraw} size='md' sx={{ minHeight: 40 }}>
                                                    Request Draw
                                                </Button>
                                                <Button fullWidth color='red'
                                                    onClick={resignGame} size='md' sx={{ minHeight: 40 }}>
                                                    Resign
                                                </Button>
                                            </Stack >
                                        </Grid.Col>
                                    </Grid>
                                </Flex>
                            )
                    }
                </Stack>
            </Center>
        </div >
    )

}

export default ChessGame