import React, { useEffect, useContext } from 'react'
import { Card, Flex, Grid, Image, Overlay, Text } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks';
import { BsFillSuitClubFill, BsFillSuitDiamondFill, BsFillSuitHeartFill, BsFillSuitSpadeFill } from 'react-icons/bs'

import { SocketContext } from '../../context/socket'

const PlayingCard = ({ suit, value, scale, flipped, folded }) => {
    const socket = useContext(SocketContext)

    if (!scale || scale === 0) {
        scale = 1
    }

    useEffect(() => {
        if (socket) {

        }
        return () => {

        }
    }, [socket]);

    const [colorScheme, setColorScheme] = useLocalStorage({
        key: 'mantine-color-scheme',
        defaultValue: 'dark',
        getInitialValueInEffect: true,
    });

    const currentSuit = () => {
        switch (suit) {
            case 0:
                return <BsFillSuitClubFill size={32 * scale} />
            case 1:
                return <BsFillSuitDiamondFill style={{ color: colorScheme === 'dark' ? 'rgb(255,118,118)' : 'red' }} size={32 * scale} />
            case 2:
                return <BsFillSuitHeartFill style={{ color: colorScheme === 'dark' ? 'rgb(255,118,118)' : 'red' }} size={32 * scale} />
            case 3:
                return <BsFillSuitSpadeFill size={32 * scale} />
            default:
                return <BsFillSuitClubFill size={32 * scale} />
        }
    };

    const currentValue = () => {
        switch (value) {
            case 14:
            case 1:
                return 'A'
            case 11:
                return 'J'
            case 12:
                return 'Q'
            case 13:
                return 'K'
            default:
                return value
        }
    };

    return (
        <Card shadow="0px 3px 6px 1px rgba(0,0,0,0.5)" padding="sm" radius="md" withBorder
            sx={(theme) => ({
                border: `2px solid ${theme.colorScheme === 'dark' ? 'gray' : theme.colors.dark[6]}`, width: 72 * scale, height: 108 * scale,
            })} >
            <Card.Section sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {folded && flipped && <Overlay opacity={0.5} zIndex={1} color='rgb(0,0,0)' />}
                {
                    !flipped ? (
                        <Grid gutter={6} columns={1} sx={{ width: '100%', height: '100%' }}>
                            <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text sx={{ fontSize: 32 * scale }}>
                                    {currentValue()}
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {currentSuit()}
                            </Grid.Col>
                        </Grid>
                    ) : (
                        <Image src='/images/ui/card_back_blue_stripe.png' pt={2.5} pr={1} pl={1} radius={8} sx={{ width: '100%', height: '100%' }} />
                    )
                }

            </Card.Section>
        </Card>
    )

}

export default PlayingCard