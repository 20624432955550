import React, { useEffect, useState, useContext } from 'react'
import { Button, Card, Center, Container, Divider, Flex, Grid, Image, Loader, Paper, SimpleGrid, Stack, Text } from '@mantine/core'

import { useNavigate } from 'react-router-dom'

import { BsPersonFill } from 'react-icons/bs'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

const PublicGameListItem = ({ user, gameInstance, gameType, setPublicGameTypeForListings }) => {
    const socket = useContext(SocketContext)

    const navigate = useNavigate()

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.6

    const joinGame = () => {
        if (socket) {
            socket.emit("join_game", { gameInstanceId: gameInstance._id })
            setPublicGameTypeForListings(null)
            navigate(`/games`)
        }
    };

    return (
        <Paper p='md' shadow='md' sx={(theme) => ({
            height: '100%',
            width: '100%',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
        })}>
            {
                gameType && gameInstance ? (
                    <Center sx={{ width: '100%' }}>
                        <Grid spacing='xl' p={6} columns={isNarrow ? 1 : 10} sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Grid.Col span={isNarrow ? 1 : 7}>
                                <Text pr='xs' pl='xs' weight={600} size='lg'>
                                    {gameInstance.owner.username}'s {gameType.name} Game
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={isNarrow ? 1 : 2} offset={isNarrow ? 0 : 1}>
                                <Stack sx={{ height: '100%' }}>
                                    <Flex sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <Text>{gameInstance.players.length} / {gameType.maxPlayers}</Text>
                                        &nbsp;
                                        <BsPersonFill />
                                    </Flex>
                                    {isNarrow && <Divider size='xs' w='100%' />}
                                    <Flex direction='column' sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                                        <Button size='md' sx={{ width: '100%' }} onClick={joinGame}>
                                            Join
                                        </Button>
                                    </Flex>
                                </Stack>
                            </Grid.Col>
                        </Grid>
                    </Center>
                ) : (
                    <Loader variant='dots' />
                )
            }
        </Paper>
    )

}

export default PublicGameListItem