import { createContext } from 'react';
import socketIOClient from 'socket.io-client';
import { localStorageUserKey } from '../constants/storageConstants';

export const SocketContext = createContext()

export const getSocket = () => {
    const localUser = JSON.parse(localStorage.getItem(localStorageUserKey));
    const token = localUser?.token; // get jwt token from local storage or cookie

    const url = process.env.ENVIRONMENT === 'development' ?
        'http://localhost:3500/' :
        'https://group-games-4e5cf92f320a.herokuapp.com/'

    if (token) {
        return socketIOClient(url, {
            query: { token },
            closeOnBeforeunload: false,
        });
    }

    throw new Error('You must be authenticated to connect to the socket server');
};
