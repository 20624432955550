import React, { useEffect, useState, useContext, useRef } from 'react'
import { Button, Dialog, Text, Stack, Grid, Loader, Center, Card, Divider, Menu } from '@mantine/core'
import PlayerAvatarSmall from '../PlayerAvatarSmall/PlayerAvatarSmall'

import { showNotification } from '@mantine/notifications'
import { useNavigate } from 'react-router-dom'

import { RiGroupFill } from 'react-icons/ri'
import { ImExit } from 'react-icons/im'

import { SocketContext } from '../../context/socket'
import UserDisplay from '../UserDisplay/UserDisplay'
import { useWindowResize } from '../Utilities/useWindowResize'

const NewGroupDisplay = ({ user, setUser, refreshUser, setGroupSize, fetchedGroup, setFetchedGroup, fetchedGroupVersion }) => {
    const socket = useContext(SocketContext)
    const navigate = useNavigate()

    const aspectRatio = useWindowResize();
    const isMedium = aspectRatio.width / aspectRatio.height < 1.2
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.65

    const fetchGroup = () => {
        if (user && user.currentGroup) {
            if (socket) {
                socket.emit("get_group", { groupId: user.currentGroup });
            }
        }
    };

    useEffect(() => {
        fetchGroup();
    }, [user]);

    // Create a ref for group
    const groupRef = useRef();
    // Make sure groupRef always refers to the latest value of fetchedGroup
    useEffect(() => {
        groupRef.current = fetchedGroup;
    }, [fetchedGroup]);

    useEffect(() => {
        if (socket) {
            socket.on("got_group", (data) => {
                if (data && data.result && data.result.group) {
                    setFetchedGroup(data.result.group)
                    setGroupSize(data.result.group.players.length)
                }
            })
            socket.on("created_group", (data) => {
                if (data && data.result && data.result.group && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                    setFetchedGroup(data.result.group)
                    setGroupSize(data.result.group.players.length)
                }
            })
            socket.on("left_group", (data) => {
                if (data && data.result && data.result.updatedUser) {
                    setUser(data.result.updatedUser)
                    setFetchedGroup(null)
                    setGroupSize(0)
                }
            })
            socket.on("joined_group", (data) => {
                if (data && data.result && data.result.updatedUser && data.result.group) {
                    setUser(data.result.updatedUser)
                    setFetchedGroup(data.result.group)
                    setGroupSize(data.result.group.players.length)
                }
            })
            socket.on("receive_group_update", (data) => {
                if (data && data.group) {
                    setFetchedGroup(data.group)
                    setGroupSize(data.group.players.length)
                }
            })
            socket.on("got_kicked_from_group", (data) => {
                if (data && data.updatedUser) {
                    setUser(data.updatedUser)
                    setFetchedGroup(null)
                    showNotification({
                        title: 'Kicked',
                        message: "You have been kicked from the group!",
                        color: 'red',
                    })
                    setGroupSize(0)
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("get_group")
                socket.off("created_group")
                socket.off("left_group")
                socket.off("joined_group")
                socket.off("receive_group_update")
                socket.off("got_kicked_from_group")
            }
        }
    }, [socket]);

    const createGroup = () => {
        if (socket) {
            socket.emit("create_group")
        }
    };

    const leaveGroup = () => {
        if (socket) {
            socket.emit("leave_group")
        }
    };

    const sendGameInvite = (userId) => {
        if (socket) {
            socket.emit("invite_to_game", { userId })
        }
    };

    const sendGroupGameInvite = () => {
        if (socket && fetchedGroup) {
            for (let i = 0; i < fetchedGroup.players.length; i++) {
                socket.emit("invite_to_game", { userId: fetchedGroup.players[i] })
            }
        }
    };

    const sendKick = (userId) => {
        if (socket) {
            socket.emit("kick_from_group", { userId })
        }
    };

    if (!user) {
        return (
            <div />
        )
    }

    const getCurrentMainButton = () => {
        if (user.currentGroup) {
            return (
                <Stack sx={{ width: '100%' }}>
                    <Button fullWidth size='sm' variant="outline" onClick={() => { sendGroupGameInvite(); }}>
                        Group Game Invite
                    </Button>
                    <Button fullWidth size='sm' variant="outline" color='red' onClick={() => { leaveGroup(); }}>
                        Leave Group
                    </Button>
                </Stack>
            )
        } else {
            return (
                <Button fullWidth size='sm' variant="outline" onClick={() => { createGroup(); }}>
                    Create Group
                </Button>
            )
        }
    };

    return (
        <Center sx={{ width: '100%' }}>
            <Stack p={8} direction="column" spacing="xs" align='center' sx={{ width: '100%' }}>
                {
                    fetchedGroup && fetchedGroup.players && fetchedGroup.players.map((player, index) => {
                        return (
                            player !== user._id ?
                                (
                                    <Menu key={index} shadow="md" position={isMedium ? 'top' : 'right'} withArrow offset={6} arrowSize={12} zIndex={5000}>
                                        <Menu.Target>
                                            <Card p={8} key={index} shadow='md' sx={(theme) => ({
                                                width: '100%', border: `${player === user._id ? '' : ''}`,
                                                '&:hover': {
                                                    backgroundColor: theme.colors.dark[5],
                                                },
                                            })}>
                                                <UserDisplay key={player} fetchUserVersion={fetchedGroupVersion} user={user} userId={player} group={fetchedGroup} checkIsOnline={true}
                                                    justifyOverride={'flex-start'} />
                                            </Card>
                                        </Menu.Target>
                                        <Menu.Dropdown p={6}>
                                            <Menu.Item onClick={() => {
                                                navigate(`/user/${player}`)
                                            }}>
                                                <Text size='xs' weight={700}>
                                                    View Profile
                                                </Text>
                                            </Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item onClick={() => {
                                                sendGameInvite(player)
                                            }}>
                                                <Text size='xs' weight={700}>
                                                    Game Invite
                                                </Text>
                                            </Menu.Item>
                                            <Menu.Item disabled>
                                                <Text size='xs' weight={700}>
                                                    Send Message
                                                </Text>
                                            </Menu.Item>
                                            <Menu.Divider />
                                            <Menu.Item color='red' onClick={() => {
                                                sendKick(player)
                                            }}>
                                                <Text size='xs' weight={700}>
                                                    Kick From Group
                                                </Text>
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                ) : (
                                    <Card p={8} key={index} sx={{ width: '100%', border: `${player === user._id ? '' : ''}` }} shadow='md'>
                                        <UserDisplay key={player} user={user} fetchUserVersion={fetchedGroupVersion} userId={player} group={fetchedGroup} checkIsOnline={true}
                                            justifyOverride={'flex-start'} />
                                    </Card>
                                )
                        )
                    })
                }
                <Divider mt={6} mb={6} sx={{ width: '100%' }} />
                {getCurrentMainButton()}
            </Stack>
        </Center>
    )

}

export default NewGroupDisplay