import React from 'react'
import { Container, Grid, Button, Text, Image, Flex } from '@mantine/core'
import { useWindowResize } from '../Utilities/useWindowResize'
import { useNavigate } from 'react-router-dom'

import { IoGameController } from 'react-icons/io5'
import { FaUserFriends } from 'react-icons/fa'
import { BsTrophy } from 'react-icons/bs'
import { FaMedal } from 'react-icons/fa'
import { FaMoneyBillWave } from 'react-icons/fa'
import { FaCoins } from 'react-icons/fa'

const NewButtonBar = ({ user }) => {
    const buttonVariant = 'default'
    const buttonHeight = 36
    const buttonImageSize = 18

    const navigate = useNavigate()

    const aspectRatio = useWindowResize();
    const isMedium = aspectRatio.width / aspectRatio.height < 1.6
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.65

    const homeButton = () => {
        navigate('/games')
    }

    /*const friendsButton = () => {
        navigate('/friends')
    }*/

    const leaderboardButton = () => {
        navigate('/leaderboard')
    }

    const freeFundsButton = () => {
        navigate('/fundsfountain')
    }

    const adminButton = () => {
        navigate('/admin-portal')
    }

    return (
        <Flex sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
            textAlign: 'center',
            padding: theme.spacing.sm,
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row',
        })}>
            <Grid gutter={8} columns={3} sx={{ marginLeft: 0, width: '100%' }}>
                <Grid.Col span={1}>
                    <Button variant={buttonVariant} onClick={homeButton} compact sx={{ width: '100%', height: buttonHeight }}>
                        {//<Image width={buttonImageSize} src='/images/ui/play.svg' />
                        }
                        <IoGameController size={buttonImageSize} />
                        {!isMedium &&
                            <Text sx={{ marginLeft: 6 }}>
                                Games
                            </Text>
                        }
                    </Button>
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button variant={buttonVariant} onClick={leaderboardButton} compact sx={{ width: '100%', height: buttonHeight }}>
                        {//<Image width={buttonImageSize} src='/images/ui/trophy.svg' />
                        }
                        <FaMedal size={buttonImageSize * 0.9} />
                        {!isMedium &&
                            <Text sx={{ marginLeft: 6 }}>
                                Leaders
                            </Text>
                        }
                    </Button>
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button variant={buttonVariant} onClick={freeFundsButton} compact sx={{ width: '100%', height: buttonHeight }}>
                        {//<Image width={buttonImageSize} src='/images/ui/trophy.svg' />
                        }
                        <FaMoneyBillWave size={buttonImageSize * 0.9} />
                        {!isMedium &&
                            <Text sx={{ marginLeft: 6 }}>
                                Funds
                            </Text>
                        }
                    </Button>
                </Grid.Col>
                {false && user && user.permissionLevel >= 2 &&
                    <Grid.Col span={3}>
                        <Button variant={buttonVariant} onClick={adminButton} compact sx={{ width: '100%', height: buttonHeight }}>
                            <Image width={buttonImageSize} src='/images/ui/wrench.svg' />
                            {!isMedium &&
                                <Text sx={{ marginLeft: 6 }}>
                                    Admin
                                </Text>
                            }
                        </Button>
                    </Grid.Col>
                }
            </Grid>
        </Flex>
    )
}

export default NewButtonBar