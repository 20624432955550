import React from 'react'
import { Image } from '@mantine/core'

import { useWindowResize } from '../Utilities/useWindowResize'

const ConnectFourPiece = ({ gameInstance, user, x, y, color }) => {
    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    return (
        <div>
            <Image src={'/images/ui/checkers_pieces_' + color + '.svg'} width={isNarrow ? 30 : 50} height={isNarrow ? 30 : 50}
                sx={{ zIndex: 5 }} />
        </div>
    )
}

export default ConnectFourPiece