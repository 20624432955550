import React, { useEffect, useState, useContext } from 'react'
import { Button, Dialog, Text, Stack, Grid, Loader, Divider } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks';
import PlayerAvatarSmall from '../PlayerAvatarSmall/PlayerAvatarSmall'

import { useNavigate } from 'react-router-dom';

import { SocketContext } from '../../context/socket'

const GameInviteDialog = ({ user, setUser, refreshUser }) => {
    const socket = useContext(SocketContext)

    const navigate = useNavigate()

    const [fetchedGame, setFetchedGame] = useState(null)
    const [fetchedSender, setFetchedSender] = useState(null)
    const [fetchedReceiver, setFetchedReceiver] = useState(null)
    const [opened, { toggle, close }] = useDisclosure(false);

    useEffect(() => {
        if (socket) {
            socket.on("receive_game_invite", (data) => {
                if (data && data.sender && data.receiver && data.gameInstance) {
                    setFetchedSender(data.sender)
                    setFetchedReceiver(data.receiver)
                    setFetchedGame(data.gameInstance)
                    toggle()
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("receive_game_invite")
            }
        }
    }, [socket]);

    const acceptGameInvite = () => {
        if (socket) {
            socket.emit("join_game", { gameInstanceId: fetchedGame._id })
            close()
            navigate(`/games`)
        }
    };

    if (!fetchedSender || !fetchedReceiver || !fetchedGame) {
        return null
    }

    return (
        <Dialog withCloseButton onClose={close} opened={opened} size={230} padding="xs" radius={10} zIndex={199}>
            <Text mt={-8} size="md" weight={700}>
                Game Invite
            </Text>
            <Divider mt={10} />
            <Text mt={10} size="md" weight={500}>
                {fetchedSender.username} has invited you to join their game!
            </Text>
            <Grid columns={2} mt={10}>
                <Grid.Col span={1}>
                    <Button fullWidth onClick={() => acceptGameInvite()} color="blue" variant="light" radius={10}>
                        Accept
                    </Button>
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button fullWidth onClick={() => close()} color="red" variant="light" radius={10}>
                        Decline
                    </Button>
                </Grid.Col>
            </Grid>
        </Dialog>
    )

}

export default GameInviteDialog