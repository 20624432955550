import React, { useEffect, useState, useContext } from 'react'
import { showNotification } from '@mantine/notifications'
import { useNavigate } from 'react-router-dom'

import { SocketContext } from '../../context/socket'

const GlobalSocketEffect = ({ setUser }) => {
    const socket = useContext(SocketContext)

    const navigate = useNavigate()

    useEffect(() => {
        if (socket) {

        }
        return () => {
            if (socket) {

            }
        }
    }, [socket]);

    return (
        <></>
    )

}

export default GlobalSocketEffect