import { Flex, ScrollArea, Tabs } from '@mantine/core'
import React, { useContext, useRef, useState, useEffect } from 'react'
import Friends from '../Friends/Friends'
import NewGroupDisplay from '../GroupDisplay/NewGroupDisplay'
import MainPlayerStatsHeader from '../MainPlayerStatsHeader/MainPlayerStatsHeader'
import { SocketContext } from '../../context/socket'

const LeftPanel = ({ user, setUser, refreshUser, showTopPanel }) => {
    const socket = useContext(SocketContext)

    const [friends, setFriends] = useState(null)
    const [fetchedGroupVersion, setFetchedGroupVersion] = useState(0)
    const [fetchedGroup, setFetchedGroup] = useState(null)

    const [groupSize, setGroupSize] = useState(0)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight)
    });

    // Create a ref for friends
    const friendsRef = useRef();

    const sortAndSetFriends = (friends) => {
        //sort friends by username and online status. the top should be online friends alphabetically, then offline friends
        friends.sort((a, b) => {
            if (a.isOnline == b.isOnline) {
                return a.username.localeCompare(b.username);
            }
            return a.isOnline ? -1 : 1;
        });
        setFriends(friends)
        friendsRef.current = friends
    }

    useEffect(() => {
        if (socket) {
            socket.on("user_came_online", (data) => {
                if (data && data.updatedUser) {
                    //find friend in friends and update their isOnline status
                    let updatedFriends = [...friendsRef.current]
                    let friendIndex = updatedFriends.findIndex(friend => friend._id == data.updatedUser._id)
                    if (friendIndex != -1) {
                        updatedFriends[friendIndex].isOnline = true
                        sortAndSetFriends(updatedFriends)
                    }
                    setFetchedGroupVersion(prev => prev + 1)
                }
            })
            socket.on("user_went_offline", (data) => {
                if (data && data.updatedUser) {
                    //find friend in friends and update their isOnline status
                    let updatedFriends = [...friendsRef.current]
                    let friendIndex = updatedFriends.findIndex(friend => friend._id == data.updatedUser._id)
                    if (friendIndex != -1) {
                        updatedFriends[friendIndex].isOnline = false
                        sortAndSetFriends(updatedFriends)
                    }
                    setFetchedGroupVersion(prev => prev + 1)
                }
            })
        }
        return () => {
            if (socket) {
                socket.off("friend_came_online")
                socket.off("friend_went_offline")
            }
        }
    }, [socket]);

    return (
        <Flex direction='column'>
            {
                showTopPanel &&
                <MainPlayerStatsHeader user={user} refreshUser={refreshUser} />
            }
            <Flex p='xs' mt={16} sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : 'white',
                textAlign: 'center',
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            })}>
                <Tabs variant='default' defaultValue='friends' color='indigo' sx={{ width: '100%' }}>
                    <Tabs.List grow>
                        <Tabs.Tab value='friends'>
                            Friends
                        </Tabs.Tab>
                        <Tabs.Tab value='group'>
                            {`Group (${groupSize})`}
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value='friends'>
                        <ScrollArea mt={16} offsetScrollbars type='always' scrollbarSize={8}
                            sx={{
                                width: '100%', height: `${windowHeight - 280}px`, overflow: 'visible',
                            }}
                            styles={{
                                scrollbar: {
                                    '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                                        backgroundColor: 'rgba(40, 40, 40, 100)',
                                    },
                                }
                            }}
                        >
                            <Flex sx={{ width: '100%' }}>
                                <Friends user={user} setUser={setUser} refreshUser={refreshUser}
                                    friends={friendsRef.current} sortAndSetFriends={sortAndSetFriends} />
                            </Flex>
                        </ScrollArea>
                    </Tabs.Panel>
                    <Tabs.Panel value='group'>
                        <ScrollArea mt={8} offsetScrollbars type='always' scrollbarSize={8}
                            sx={{
                                width: '100%', height: `${windowHeight - 272}px`, overflow: 'visible',
                            }}
                            styles={{
                                scrollbar: {
                                    '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                                        backgroundColor: 'rgba(40, 40, 40, 100)',
                                    },
                                }
                            }}>
                            <Flex sx={{ width: '100%' }}>
                                <NewGroupDisplay user={user} setUser={setUser} refreshUser={refreshUser} setGroupSize={setGroupSize}
                                    fetchedGroup={fetchedGroup} setFetchedGroup={setFetchedGroup} fetchedGroupVersion={fetchedGroupVersion} />
                            </Flex>
                        </ScrollArea>
                    </Tabs.Panel>
                </Tabs>
            </Flex>
        </Flex>
    )
}

export default LeftPanel