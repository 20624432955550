import React, { useEffect, useState, useContext } from 'react'
import { Badge, Button, Center, Container, Flex, Grid, Loader, NumberInput, Overlay, Slider, Stack, Text } from '@mantine/core'

import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

const CheckersSquare = ({ gameInstance, user, x, y, color, children, highlight }) => {
    const socket = useContext(SocketContext)

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: 'checkers_piece',
            canDrop: () => true,
            drop: (item) => {
                if (socket) {
                    socket.emit('perform_checkers_action', { action: `${item.y},${item.x},${y},${x}` })
                }
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver(),
                canDrop: !!monitor.canDrop(),
            }),
        }),
        [],
    )

    return (
        <div
            ref={drop}
            role="Space"
            data-testid={`(${x},${y})`}
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
        >
            {highlight && <Overlay zIndex={0} opacity={0.1} color='yellow' sx={{ width: isNarrow ? '35px' : '60px', height: isNarrow ? '35px' : '60px' }} />}
            <Flex sx={{
                width: isNarrow ? '35px' : '60px', height: isNarrow ? '35px' : '60px', backgroundSize: 'contain', backgroundImage: `url(/images/ui/${color}_board_square.svg)`,
            }}
                justify='center' align='center'>
                {
                    children
                }
            </Flex>
        </div>
    )
}

export default CheckersSquare