import axios from 'axios'
import { localStorageUserKey } from '../constants/storageConstants'

const API = process.env.ENVIRONMENT === 'development' ? 
    axios.create({ baseURL: 'http://localhost:5000/' }) :
    axios.create({ baseURL: 'https://group-games-4e5cf92f320a.herokuapp.com/' })


API.interceptors.request.use((req) => {
    if (localStorage.getItem(localStorageUserKey)) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem(localStorageUserKey)).token}`
    }
    return req
})

export const fetchUser = (id) => API.get(`/user/${id}`)
export const signin = (formData) => API.post('/user/signin', formData)
export const googlesignin = (formData) => API.post('/user/googlesignin', formData)
export const signup = (formData) => API.post('/user/signup', formData)