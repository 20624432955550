import React, { useEffect, useState, useContext, useId } from 'react'
import { Button, Grid, Loader, Menu, Text } from '@mantine/core'

import { SocketContext } from '../../context/socket'

const PlayerAvatarSmall = ({ user, userId, group, scale }) => {
    const socket = useContext(SocketContext)

    const [fetchedUser, setFetchedUser] = useState(null)

    if (!scale || scale === 0) {
        scale = 1
    }

    useEffect(() => {
        if (socket) {
            socket.emit('fetch_user', { userId, context: 'player_avatar_small' })
            socket.on('fetched_user', (data) => {
                if (data && data.result && data.result.context === 'player_avatar_small' &&
                    data.result.updatedUser && data.result.updatedUser._id === userId) {
                    setFetchedUser(data)
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('fetched_user');
            }
        }
    }, [socket]);

    const getColorFromSeed = (seed) => {
        let hashCode = 0;
        for (let i = 0; i < seed.length; i++) {
            hashCode = seed.charCodeAt(i) + ((hashCode << 5) - hashCode);
        }

        const hexCode = (hashCode & 0x00FFFFFF).toString(16).toUpperCase();
        const paddedHexCode = "00000".substring(0, 6 - hexCode.length) + hexCode;
        const color = "#" + paddedHexCode;

        return color;
    };

    const sendGameInvite = () => {
        if (socket) {
            socket.emit("invite_to_game", { userId: userId })
        }
    };

    const sendKick = () => {
        if (socket) {
            socket.emit("kick_from_group", { userId: userId })
        }
    };

    if (!fetchedUser || !fetchedUser.result) {
        return (
            <div>
                <Loader variant='oval' />
            </div>
        )
    }

    return (
        <div>
            {
                user._id !== userId ? (
                    <Menu shadow="md" width={150} position='left' withArrow offset={10} arrowSize={12}>
                        <Menu.Target>
                            <Button radius={1000} size='xs' variant="filled" sx={{
                                width: 50 * scale, minHeight: 50 * scale,
                                background: getColorFromSeed(fetchedUser.result.updatedUser.username)
                            }}>
                                <Text size={11} weight={700}>
                                    {fetchedUser.result.updatedUser.username.charAt(0).toUpperCase()}
                                </Text>
                            </Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label>{fetchedUser.result.updatedUser.username}</Menu.Label>
                            <Menu.Divider />
                            <Menu.Item onClick={sendGameInvite} disabled={user._id === userId}>
                                <Text size='xs' weight={700}>
                                    Invite to Game
                                </Text>
                            </Menu.Item>
                            <Menu.Item color='red' onClick={sendKick} disabled={user._id === userId}>
                                <Text size='xs' weight={700}>
                                    Kick
                                </Text>
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                ) : (
                    <Button radius={1000} size='xs' variant="filled" sx={(theme) => ({
                        width: 50 * scale, minHeight: 50 * scale,
                        background: getColorFromSeed(fetchedUser.result.updatedUser.username),
                        border: `2px solid ${theme.colors.blue[5]}}`
                    })}>
                        <Text size={11} weight={700}>
                            {fetchedUser.result.updatedUser.username.charAt(0).toUpperCase()}
                        </Text>
                    </Button>
                )
            }
        </div>

    )

}

export default PlayerAvatarSmall