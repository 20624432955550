import React, { useEffect, useState, useContext, useRef } from 'react'
import { Badge, Button, Container, Flex, Grid, Loader, NumberInput, Slider, Stack, Text } from '@mantine/core'

import { showNotification } from '@mantine/notifications'
import { useLocalStorage } from '@mantine/hooks';

import PlayingCard from '../PlayingCard/PlayingCard'
import PokerSeat from '../PokerSeat/PokerSeat'

import { AiFillCheckCircle } from 'react-icons/ai'
import { BsXCircleFill } from 'react-icons/bs'

import { getAvailablePokerActions, getLabelForPokerAction } from '../Utilities/pokerUtilities'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

const PokerGame = ({ user, refreshUser, setUser, currentGameInstance, setCurrentGameInstance, setIsExploding }) => {
    const socket = useContext(SocketContext)

    const [players, setPlayers] = useState(Array.from({ length: 8 }))
    const [currentBet, setCurrentBet] = useState(0)
    const [fetchUserVersion, setFetchUserVersion] = useState(0);

    const [autoChecking, setAutoChecking] = useState(false);
    const [callingAny, setCallingAny] = useState(false);
    const [checkFolding, setCheckFolding] = useState(false);

    const [alertVolume, setAlertVolume] = useLocalStorage({
        key: 'alert-volume',
        defaultValue: '30',
        getInitialValueInEffect: true,
    });

    const [sfxVolume, setSFXVolume] = useLocalStorage({
        key: 'sfx-volume',
        defaultValue: '30',
    });

    const turnDing = useRef(new Audio('/sounds/bicycle-bell.mp3'));
    const chipsSFX = useRef(new Audio('/sounds/poker_chips.mp3'));
    const winSFX = useRef(new Audio('/sounds/success_bell.mp3'));

    useEffect(() => {
        turnDing.current.volume = Number(alertVolume) / 100;
        chipsSFX.current.volume = Number(sfxVolume) / 100;
        winSFX.current.volume = Number(sfxVolume) / 100;
    }, [alertVolume, sfxVolume]);

    const playTurnDing = () => {
        turnDing.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    const playChipsSFX = () => {
        chipsSFX.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    const playWinSFX = () => {
        winSFX.current.play().catch((error) => {
            console.log('Failed to play audio:', error);
        });
    }

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    const tryPerformAutoAction = (gameState) => {
        const availableActions = getAvailablePokerActions(gameState, user)
        if (callingAny) {
            if (availableActions.includes('call')) {
                performAction('call')
            } else if (availableActions.includes('check')) {
                performAction('check')
            }
        } else if (checkFolding) {
            if (availableActions.includes('check')) {
                performAction('check')
            } else if (availableActions.includes('fold')) {
                performAction('fold')
            }
        } else if (autoChecking) {
            if (availableActions.includes('check')) {
                performAction('check')
            }
        }
    }

    useEffect(() => {
        if (currentGameInstance && currentGameInstance.players) {
            let modifiedPlayers = Array.from({ length: 8 })
            currentGameInstance.players.forEach((player, index) => {
                modifiedPlayers[index] = player
            })
            setPlayers(modifiedPlayers)
            setFetchUserVersion(prev => prev + 1);
        }
    }, [currentGameInstance]);

    useEffect(() => {
        if (socket) {
            socket.on('performed_texas_hold_em_action', (data) => {
                if (data) {
                    if (data.result) {
                        if (data.result.error) {
                            showNotification({
                                title: 'Error',
                                message: data.result.error,
                                color: 'red'
                            })
                        }
                    }
                }
            })
            socket.on('receive_game_instance_update', (data) => {
                if (data && data.gameInstance) {
                    if (data.action) {
                        if (data.action === 'show_cards') {
                            setCurrentGameInstance(data.gameInstance)
                            return;
                        }
                    }
                    if (data.gameInstance.gameState && data.gameInstance.gameState.playerStates) {
                        const currentTotalBets = Object.keys(currentGameInstance.gameState.playerStates).reduce((total, key) => {
                            return total + currentGameInstance.gameState.playerStates[key].bet
                        }, 0)
                        const newTotalBets = Object.keys(data.gameInstance.gameState.playerStates).reduce((total, key) => {
                            return total + data.gameInstance.gameState.playerStates[key].bet
                        }, 0)
                        if (newTotalBets > currentTotalBets) {
                            playChipsSFX()
                        }
                    }
                    setCurrentGameInstance(data.gameInstance)
                    if (data.updatedUser) {
                        setUser(data.updatedUser)
                    }
                    if (data.gameInstance.gameState.phase !== 0 && data.gameInstance.gameState.turn === user._id &&
                        (!data.gameInstance.gameState.winners || data.gameInstance.gameState.winners.length < 1)) {
                        tryPerformAutoAction(data.gameInstance.gameState)
                        playTurnDing()
                        setIsExploding(false)
                    } else {
                        if (data.gameInstance.gameState.winners && data.gameInstance.gameState.winners.length > 0) {
                            if (data.gameInstance.gameState.winners.includes(user._id)) {
                                setIsExploding(true)
                            }
                            playWinSFX()
                        }
                    }
                }
            })
        }
        return () => {
            if (socket) {
                socket.off('performed_texas_hold_em_action')
                socket.off('receive_game_instance_update')
            }
        }
    }, [socket, callingAny, checkFolding, autoChecking]);

    if (!currentGameInstance || !currentGameInstance.gameState) {
        return (
            <Flex p='lg' justify="center" align="center">
                <Loader />
            </Flex>
        )
    } else {
        //console.log(currentGameInstance)
    }

    const callAny = () => {
        if (checkFolding) {
            setCheckFolding(false)
        }
        if (autoChecking) {
            setAutoChecking(false)
        }
        setCallingAny(!callingAny)
    };

    const checkFold = () => {
        if (callingAny) {
            setCallingAny(false)
        }
        if (autoChecking) {
            setAutoChecking(false)
        }
        setCheckFolding(!checkFolding)
    };

    const autoCheck = () => {
        if (checkFolding) {
            setCheckFolding(false)
        }
        if (callingAny) {
            setCallingAny(false)
        }
        setAutoChecking(!autoChecking)
    };

    const performAction = (action) => {
        if (action !== 'callany' && action !== 'checkfold' && action !== 'autocheck') {
            if (action !== 'leave') {
                if (action === 'bet' || action === 'raise') {
                    if (socket) {
                        socket.emit('perform_texas_hold_em_action', { action: action, amount: currentBet })
                    }
                } else {
                    if (socket) {
                        socket.emit('perform_texas_hold_em_action', { action: action })
                    }
                }
                setCurrentBet(0)
                setCallingAny(false)
                setCheckFolding(false)
                setAutoChecking(false)
            } else {
                if (socket) {
                    socket.emit('leave_game')
                }
            }
        } else {
            if (action === 'checkfold') {
                checkFold()
            } else if (action === 'callany') {
                callAny()
            } else if (action === 'autocheck') {
                autoCheck()
            }
        }
    }

    const selectButtonColor = (action) => {
        if (action === 'fold' || action === 'leave') {
            return 'red';
        }
        if (action === 'checkfold') {
            return checkFolding ? 'green' : 'red';
        }
        if (action === 'callany') {
            return callingAny ? 'green' : 'red';
        }
        if (action === 'autocheck') {
            return autoChecking ? 'green' : 'red';
        }
        if (action === 'start') {
            return 'green';
        }
        return 'blue';
    };

    const getButtonIcon = (action) => {
        if (action === 'checkfold') {
            return checkFolding && <AiFillCheckCircle size={16} style={{ marginRight: 5 }} />
        }
        if (action === 'callany') {
            return callingAny && <AiFillCheckCircle size={16} style={{ marginRight: 5 }} />
        }
        if (action === 'autocheck') {
            return autoChecking && <AiFillCheckCircle size={16} style={{ marginRight: 5 }} />
        }
        return null;
    };

    const getButtonsComponent = (actions) => {
        return (
            //if actions 
            actions.map((action, index) => {
                return (
                    <Button fullWidth key={index}
                        color={selectButtonColor(action)}
                        onClick={() => { performAction(action) }} size='md' sx={{ minHeight: 40 }}>
                        {getButtonIcon(action)}
                        {`${getLabelForPokerAction(action)}` + (action === 'call' ? ` ${getCallAmount()}` : '')}
                    </Button>
                )
            }))
    };

    const getCallAmount = () => {
        const playersInGame = Object.keys(currentGameInstance.gameState.playerStates);
        let highestBet = 0
        playersInGame.forEach((player) => {
            const playerState = currentGameInstance.gameState.playerStates[player]
            if (Number(playerState.bet) > highestBet) {
                highestBet = Number(playerState.bet)
            }
        });
        if (highestBet > Number(user.funds)) {
            return Number(user.funds)
        } else {
            return highestBet - Number(currentGameInstance.gameState.playerStates[user._id].bet)
        }
    }

    const getActionButtons = () => {
        let availableActions = getAvailablePokerActions(currentGameInstance.gameState, user)
        //sort alphabetically
        availableActions.sort()
        if (availableActions instanceof Error || !availableActions) {
            return (
                null
            )
        }
        //check for "start" action, and then push it to the front of the array
        const startActionIndex = availableActions.indexOf('start')
        if (startActionIndex !== -1) {
            availableActions.splice(startActionIndex, 1)
            availableActions.unshift('start')
        }
        if (availableActions.length === 0) {
            if (!currentGameInstance.gameState.playerStates[user._id].folded && !currentGameInstance.gameState.playerStates[user._id].allIn) {
                availableActions.push('autocheck')
                availableActions.push('callany')
                availableActions.push('checkfold')
            }
        }
        //check if folded or if game is over by checking winners has length - if so, include "show_cards" action
        if (currentGameInstance.gameState.winners && currentGameInstance.gameState.winners.length > 0) {
            availableActions.push('show_cards')
            //push it to the front of the array
            const showCardsActionIndex = availableActions.indexOf('show_cards')
            if (showCardsActionIndex !== -1) {
                availableActions.splice(showCardsActionIndex, 1)
                availableActions.unshift('show_cards')
            }
        }
        if (availableActions.length === 0) {
            return (
                null
            )
        }
        const betRaiseActions = availableActions.filter((action) => {
            return action === 'bet' || action === 'raise'
        })
        const allOtherActions = availableActions.filter((action) => {
            return action !== 'bet' && action !== 'raise'
        })
        return (
            betRaiseActions.length > 0 ? (
                <Grid p='xs' columns={isNarrow ? 1 : 20} dir='vertical' sx={{ width: '100%' }}>
                    <Grid.Col p='xs' span={isNarrow ? 1 : 7} offset={isNarrow ? 0 : 2}>
                        <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                            <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                                <Slider
                                    value={currentBet}
                                    onChange={(value) => {
                                        setCurrentBet(value)
                                    }}
                                    max={user.funds}
                                    step={5}
                                    label={<Text>Bet: {currentBet}</Text>}
                                    color='dark'
                                    marks={[
                                        { value: `${user.funds * 0.25}` },
                                        { value: `${user.funds * 0.50}` },
                                        { value: `${user.funds * 0.75}` },
                                    ]}
                                    sx={{ width: '100%' }}
                                />
                                <NumberInput value={currentBet} onChange={setCurrentBet} min={0} max={user.funds} step={5} />
                            </Stack>
                            {
                                getButtonsComponent(betRaiseActions)
                            }
                        </Stack>
                    </Grid.Col>
                    <Grid.Col p='xs' span={isNarrow ? 1 : 7} offset={isNarrow ? 0 : 2}>
                        <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                            {
                                getButtonsComponent(allOtherActions)
                            }
                        </Stack >
                    </Grid.Col>
                </Grid>
            ) : (
                <Grid p='xs' columns={isNarrow ? 1 : 4} dir='vertical' sx={{ width: '100%' }}>
                    <Grid.Col p='xs' span={isNarrow ? 1 : 2} offset={isNarrow ? 0 : 1}>
                        <Stack justify='center' spacing="xs" sx={{ width: '100%', height: '100%' }}>
                            {
                                getButtonsComponent(allOtherActions)
                            }
                        </Stack >
                    </Grid.Col>
                </Grid>
            ))
    };


    let playerIndex = 0

    const potBadgeTop = isNarrow ? -2 : 20;
    const potBadgeLeft = isNarrow ? 0 : 30;

    return (
        <div>
            <Flex sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                padding: theme.spacing.sm,
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: 26
            })}>
                <Stack sx={{ width: '100%' }}>
                    <Grid columns={isNarrow ? 2 : 4} sx={{ width: '100%' }}>
                        {
                            players.slice(0, 4).map((player, index) => {
                                return (
                                    <PokerSeat key={playerIndex++} index={index} userId={player} gameInstance={currentGameInstance} user={user}
                                        fetchUserVersion={fetchUserVersion} />
                                )
                            })
                        }
                        <Grid.Col span={isNarrow ? 2 : 4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 180 }}>
                            <Flex sx={(theme) => ({
                                border: `8px outset ${theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.dark[3]}`,
                                width: '100%', height: '100%', background: 'green', borderRadius: 60,
                                position: 'relative', justifyContent: 'center', alignContent: 'center', alignItems: 'center',
                                boxShadow: "0px 2px 8px 1px rgba(0,0,0,0.6)"
                            })} >
                                <Badge radius='md' color='dark' size='lg' variant='filled' sx={(theme) => ({
                                    position: 'absolute',
                                    top: `${isNarrow ? -2 : 20}px`,
                                    ...(!isNarrow && { left: '30px' }),
                                    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.6)",
                                    //border: `4px inset ${theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.dark[3]}`,
                                })}>
                                    <Text>
                                        Pot: {currentGameInstance.gameState.pot}
                                    </Text>
                                </Badge>
                                {
                                    currentGameInstance.gameState.communityCards ? (
                                        currentGameInstance.gameState.communityCards.map((card, index) => {
                                            return (
                                                <Flex key={index} p={2}>
                                                    <PlayingCard suit={card.suit} value={card.value} scale={isNarrow ? 0.72 : 1} />
                                                </Flex>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )
                                }
                            </Flex>
                        </Grid.Col>
                        {
                            players.slice(4, 8).map((player, index) => {
                                return (
                                    <PokerSeat key={playerIndex++} index={index + 4} userId={player} gameInstance={currentGameInstance} user={user}
                                        fetchUserVersion={fetchUserVersion} />
                                )
                            })
                        }
                    </Grid>
                </Stack>
            </Flex>
            <Flex sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                padding: theme.spacing.sm,
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
                marginTop: 16,
                paddingLeft: 26,
            })}>
                {
                    getActionButtons()
                }
            </Flex>
        </div >
    )

}

export default PokerGame