import React, { useEffect, useState, useContext } from 'react'
import { Button, Card, Center, Container, Divider, Flex, Grid, Image, Loader, Paper, SimpleGrid, Stack, Text } from '@mantine/core'

import { BsPersonFill } from 'react-icons/bs'
import { RxMagnifyingGlass } from 'react-icons/rx'

import { useWindowResize } from '../Utilities/useWindowResize'
import { SocketContext } from '../../context/socket'

const GameSelectItem = ({ user, game, setSelectedGame, setPublicGameTypeForListings, findGame, playGame }) => {
    const socket = useContext(SocketContext)

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.6

    return (
        <Paper p='md' shadow='md' sx={(theme) => ({
            height: '100%',
            width: '100%',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
        })}>
            {
                game ? (
                    <Center sx={{ width: '100%' }}>
                        <Grid spacing='xl' p={6} columns={isNarrow ? 1 : 10} sx={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Grid.Col span={isNarrow ? 1 : 2}>
                                <Center sx={{ width: '100%' }}>
                                    <Image height={100} src={game.imageSource} radius={16} sx={{ maxWidth: 130, width: '100%' }} />
                                </Center>
                            </Grid.Col>
                            <Grid.Col span={isNarrow ? 1 : 5}>
                                <Stack>
                                    <Text pr='xs' pl='xs' weight={600} size='lg'>
                                        {game.name}
                                    </Text>
                                    <Divider size='xs' w='100%' />
                                    <Text pb='xs' pr='xs' pl='xs' size={14}>
                                        {game.description}
                                    </Text>
                                </Stack>
                            </Grid.Col>
                            <Grid.Col span={isNarrow ? 1 : 2} offset={isNarrow ? 0 : 1}>
                                <Stack sx={{ height: '100%' }}>
                                    <Flex sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            game.minPlayers === game.maxPlayers ? (
                                                <Text>{game.maxPlayers}</Text>
                                            ) : (
                                                <Text>{game.minPlayers} - {game.maxPlayers}</Text>
                                            )
                                        }
                                        &nbsp;
                                        <BsPersonFill />
                                    </Flex>
                                    {isNarrow && <Divider size='xs' w='100%' />}
                                    <Flex direction='column' sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                                        {
                                            game.underConstruction && (user.permissionLevel !== 2) ? (
                                                <Text color='red' size='sm'>Under Construction</Text>
                                            ) : (
                                                <Stack spacing='xs' sx={{ width: '100%' }}>
                                                    {
                                                        game.maxPlayers == 1 ? (
                                                            <Button variant='filled' size='sm' sx={{ width: '100%' }}
                                                                onClick={() => playGame(game._id)}
                                                            >
                                                                Play
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button variant='outline' size='sm' sx={{ width: '100%' }}
                                                                    onClick={() => { findGame(game.uniqueName) }}
                                                                >
                                                                    <RxMagnifyingGlass />
                                                                    &nbsp;
                                                                    Find
                                                                </Button>
                                                                <Button size='sm' sx={{ width: '100%' }}
                                                                    onClick={() => setSelectedGame(game)}
                                                                >
                                                                    Host
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                </Stack>
                                            )
                                        }
                                    </Flex>
                                </Stack>
                            </Grid.Col>
                        </Grid>
                    </Center >
                ) : (
                    <Loader variant='dots' />
                )
            }
        </Paper >
    )

}

export default GameSelectItem