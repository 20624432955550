import React, { useEffect, useState, useContext } from 'react'
import { Card, Center, Container, Divider, Flex, Grid, Image, Loader, Paper, ScrollArea, SimpleGrid, Stack, Tabs, Text } from '@mantine/core'

import GameSelectItem from '../GameSelectItem/GameSelectItem'
import { useLocation } from 'react-router-dom'
import NumericLabel from 'react-pretty-numbers'

import { SocketContext } from '../../context/socket'

const UserProfile = ({ user, refreshUser, setUser }) => {
    const socket = useContext(SocketContext)

    const location = useLocation()
    const splitPath = location.pathname.split('/')
    const userId = splitPath[splitPath.length - 1]

    const [games, setGames] = useState([])

    const [fetchedUser, setFetchedUser] = useState(null)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight)
    });

    useEffect(() => {
        if (games.length > 0) {
            games.forEach((game) => {
                if (socket) {
                    socket.emit("get_top_users_for_game_type", { gameType: game.uniqueName })
                }
            })
        }
    }, [games]);

    useEffect(() => {
        if (socket) {
            if (!games || games.length === 0) {
                socket.emit('get_all_games')
            }

            socket.on('got_all_games', (data) => {
                if (data && data.result && data.result.games) {
                    //sort games by name
                    const sortedGames = data.result.games.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else if (a.name < b.name) {
                            return -1
                        } else {
                            return 0
                        }
                    })
                    const filteredGames = sortedGames.filter((game) => game.underConstruction === false)
                    setGames(filteredGames)
                }
            });

            socket.emit('fetch_user', { userId, context: 'user_profile' })
            socket.on('fetched_user', (data) => {
                if (data && data.result && data.result.context === 'user_profile' && data.result.updatedUser &&
                    data.result.updatedUser._id === userId) {
                    setFetchedUser(data)
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('got_all_games')
                socket.off('fetched_user')
            }
        }
    }, [socket, userId]);

    let prettyNumberOptions = {
        'justification': 'C',
        'shortFormat': true,
        'shortFormatMinValue': 10000,
        'shortFormatPrecision': 2
    }

    const getFundsString = (amount) => {
        return (
            <Flex justify='center' align='center'>
                <Image width={16} src='/images/ui/dollar.svg' />
                &nbsp;&nbsp;
                <Text weight={400}>
                    <NumericLabel params={prettyNumberOptions}>
                        {amount}
                    </NumericLabel>
                </Text>
            </Flex>
        )
    };

    const getColorFromSeed = (seed) => {
        let hashCode = 0;
        for (let i = 0; i < seed.length; i++) {
            hashCode = seed.charCodeAt(i) + ((hashCode << 5) - hashCode);
        }

        const hexCode = (hashCode & 0x00FFFFFF).toString(16).toUpperCase();
        const paddedHexCode = "00000".substring(0, 6 - hexCode.length) + hexCode;
        const color = "#" + paddedHexCode;

        return color;
    };

    if (!fetchedUser || !games || !games.length) {
        return (
            <Flex p='md' justify='center' sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
                textAlign: 'center',
                borderRadius: theme.radius.md,
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            })}>
                <Loader />
            </Flex>
        )
    }

    return (
        <Flex p='md' sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
            textAlign: 'center',
            borderRadius: theme.radius.md,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        })}>
            <Center sx={{ width: '100%' }}>
                <ScrollArea offsetScrollbars type='always' sx={{ width: '100%', height: `${windowHeight - 170}px` }}>
                    <Paper p='lg' shadow='md' sx={(theme) => ({
                        height: '100%',
                        width: '100%',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                    })}>
                        <Flex direction='row' align='center' sx={{ width: '100%', height: '100%' }}>
                            <Flex justify='center' align='center'
                                sx={{ height: 70, width: 70, borderRadius: 999, backgroundColor: getColorFromSeed(fetchedUser.result.updatedUser.username) }}>
                                <Text size={36} weight={700}>
                                    {fetchedUser.result.updatedUser.username.charAt(0).toUpperCase()}
                                </Text>
                            </Flex>
                            <Divider orientation='vertical' ml={16} />
                            <Flex direction='column' justify='center' align='flex-start' sx={{ marginLeft: 20 }}>
                                <Text size='lg' weight={700}>
                                    {fetchedUser.result.updatedUser.username}
                                </Text>
                                {
                                    getFundsString(fetchedUser.result.updatedUser.funds)
                                }
                            </Flex>
                        </Flex>
                    </Paper>
                    <Paper mt={12} p='lg' shadow='md' sx={(theme) => ({
                        height: '100%',
                        width: '100%',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
                    })}>
                        <Tabs variant='default' defaultValue={`${games[0].uniqueName}`} color='indigo' sx={{ width: '100%' }}>
                            <Tabs.List grow>
                                {
                                    games.map((game, index) => {
                                        return (
                                            <Tabs.Tab key={index} value={`${game.uniqueName}`}>
                                                {game.name}
                                            </Tabs.Tab>
                                        )
                                    })
                                }
                            </Tabs.List>
                            {
                                games.map((game, index) => {
                                    return (
                                        <Tabs.Panel key={index} value={`${game.uniqueName}`}>
                                            {
                                                fetchedUser.result.updatedUser.record && fetchedUser.result.updatedUser.record[game.uniqueName] ?
                                                    <Flex mt={16} direction='row' sx={{ width: '100%' }}>
                                                        <SimpleGrid cols={2} sx={{ width: '100%' }}>
                                                            <Card p='xs' sx={{ width: '100%' }}>
                                                                <Text>
                                                                    {`Wins: ${fetchedUser.result.updatedUser.record[game.uniqueName].wins}`}
                                                                </Text>
                                                            </Card>
                                                            <Card p='xs' sx={{ width: '100%' }}>
                                                                <Text>
                                                                    {`Losses: ${fetchedUser.result.updatedUser.record[game.uniqueName].losses}`}
                                                                </Text>
                                                            </Card>
                                                            <Card p='xs' sx={{ width: '100%' }}>
                                                                <Text>
                                                                    {`Draws: ${fetchedUser.result.updatedUser.record[game.uniqueName].draws}`}
                                                                </Text>
                                                            </Card>
                                                            <Card p='xs' sx={{ width: '100%' }}>
                                                                <Text>
                                                                    {`Total Games: ${fetchedUser.result.updatedUser.record[game.uniqueName].gamesPlayed}`}
                                                                </Text>
                                                            </Card>
                                                            <Card p='xs' sx={{ width: '100%' }}>
                                                                <Flex direction='row' justify='center' align='center' sx={{ width: '100%' }}>
                                                                    <Text mr={10}>
                                                                        {`Total Won:`}
                                                                    </Text>
                                                                    {
                                                                        getFundsString(fetchedUser.result.updatedUser.record[game.uniqueName].totalWon)
                                                                    }
                                                                </Flex>
                                                            </Card>
                                                            <Card p='xs' sx={{ width: '100%' }}>
                                                                <Flex direction='row' justify='center' align='center' sx={{ width: '100%' }}>
                                                                    <Text mr={10}>
                                                                        {`Total Bet:`}
                                                                    </Text>
                                                                    {
                                                                        getFundsString(fetchedUser.result.updatedUser.record[game.uniqueName].totalBet)
                                                                    }
                                                                </Flex>
                                                            </Card>
                                                            <Card p='xs' sx={{ width: '100%' }}>
                                                                <Flex direction='row' justify='center' align='center' sx={{ width: '100%' }}>
                                                                    <Text mr={10}>
                                                                        {`Total Profit:`}
                                                                    </Text>
                                                                    {
                                                                        getFundsString(fetchedUser.result.updatedUser.record[game.uniqueName].totalWon -
                                                                            fetchedUser.result.updatedUser.record[game.uniqueName].totalBet)
                                                                    }
                                                                </Flex>
                                                            </Card>
                                                        </SimpleGrid>
                                                    </Flex>
                                                    :
                                                    <Flex mt={16} direction='row' sx={{ width: '100%' }}>
                                                        <Text>
                                                            No record found!
                                                        </Text>
                                                    </Flex>
                                            }
                                        </Tabs.Panel>
                                    )
                                })
                            }
                        </Tabs>
                    </Paper>
                </ScrollArea>
            </Center>
        </Flex>
    )

}

export default UserProfile