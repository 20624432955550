import React from 'react'
import { Flex, Overlay } from '@mantine/core'

import { useWindowResize } from '../Utilities/useWindowResize'

const ConnectFourSquare = ({ gameInstance, user, x, y, color, children, highlight, selectHighlight }) => {

    const aspectRatio = useWindowResize();
    const isNarrow = aspectRatio.width / aspectRatio.height < 0.7;

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
        >
            {selectHighlight && <Overlay zIndex={0} opacity={0.1} color='white' sx={{ width: isNarrow ? '35px' : '60px', height: isNarrow ? '35px' : '60px' }} />}
            {highlight && <Overlay zIndex={0} opacity={0.4} color='yellow' sx={{ width: isNarrow ? '35px' : '60px', height: isNarrow ? '35px' : '60px' }} />}
            <Flex sx={{
                width: isNarrow ? '35px' : '60px', height: isNarrow ? '35px' : '60px', backgroundSize: 'contain', backgroundImage: `url(/images/games/connectfour/connect_four_slot_dblue.svg)`,
            }}
                justify='center' align='center'>
                {
                    children
                }
            </Flex>
        </div>
    )
}

export default ConnectFourSquare